import React, { useState, useEffect } from "react";
import "./Footer.css";
import KochLogo from "../../assets/Koch-logo.png";
import KochLogoFooter from "../../assets/Koch-logo-footer.png";
import FacebookIcon from "../../assets/facebook.png";
import Linkdein from "../../assets/Icons/linkedin.png"
import TwitterIcon from "../../assets/twitter.png";
import YouTubeIcon from "../../assets/youtube.png";
import FindRep from "../FindRep/FindRep.js";
import { useLocation, Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import RegionSelector from "../RegionSelector.js";
import protivateImg from '../../assets/protivateimg.png';
import protivateImgCa from '../../assets/protivatecampaign-ca-product.png';

const footerClassMapping = {
  '/': '',
  '/agrotain': 'green agrotain',
  '/centuro': 'purple centuro',
  '/superu': 'blue superu',
  '/protivate': 'red protivate',
  '/optrient': 'orange optrient',
  '/anvol': 'green anvol-us',
  '/anvol-ca': 'green anvol-ca',
  '/superu-ca': 'blue superu-ca',
  '/tribune-ca': 'tribune-blue',
  '/agrotain-ca': 'green agrotain-ca',
  '/product-labels': 'product-labels',
  '/find-a-rep': 'contactreps',
  '/reps-retailers': 'reps-retailers',
  '/wolf-trax': 'wolftrax-us',
  '/wolf-trax-ca': 'wolftrax-us wolftrax-ca',
  '/centuro-ca': 'purple centuro-ca',
    '/rocket-seeds-ca': 'red',
  '/protivate-ca': 'red protivate',
  '/nitamin-ca': 'orange',
  '/synthos-ca': 'synthos-ca',
  '/home-ca': 'home-ca',
  '/home-int': 'home-int',
  '/clear-choice': 'purple',
  '/balanced-nutrition': 'wolftrax-us',
  '/thats-farming': 'blue',
  '/anvol-protects': 'green',
  '/dual-purpose': 'red',
  '/dual-purpose-ca': 'red',
};



function Footer() {
  const currentRegion = useSelector((state) => state.region.currentRegion);
  const location = useLocation();
  const navigate = useNavigate();

  const productsUS = {
    "AGROTAIN": "/agrotain",
    "WOLF TRAX": "/wolf-trax",
    "PROTIVATE": "/protivate",
    "ANVOL": "/anvol",
    "OPTRIENT": "/optrient",
    "CENTURO": "/centuro",
    "SUPERU": "/superu"
  };
  
  const productsCanada = {
    "AGROTAIN": "/agrotain-ca",
    "WOLF TRAX": "/wolf-trax-ca",
    "PROTIVATE": "/protivate-ca",
    "ANVOL": "/anvol-ca",
    "NITAMIN": "/nitamin-ca",
    "CENTURO": "/centuro-ca",
    "SUPERU": "/superu-ca",
    "SYNTHOS": "/synthos-ca",
    "TRIBUNE": "/tribune-ca"
  };
  
  const pathSegment = (location.pathname.lastIndexOf('/') + 1 === location.pathname.length ? location.pathname.substring(0, location.pathname.length - 1) : location.pathname);
    const footerClass = footerClassMapping[`${pathSegment}`] || "";
    const [showProtivateBox, setProtivateBox] = useState(false);

    useEffect(() => {
        if (window.location.pathname.indexOf('dual-purpose') >= 0) {
            if (showProtivateBox == false) { setProtivateBox(true); }
        } else if (showProtivateBox == true) {
            setProtivateBox(false);
        }
    });

  if (location.pathname === "/reps-retailers" ||  location.pathname === "/product-selector") return null;

  if (currentRegion === 4) {
    return (
      <>
        {" "}
        <div className="footer-wrap">
          <div className="footer-center">
            <div className="footer-link-wrap" role="navigation">
              <RegionSelector />
                  <div className="footer-logo">
                     <img src={KochLogo} alt="Koch Logo" width="278" height="90" />
              </div>
            </div>
            <FooterCopyRight />
          </div>
        </div>
      </>
    );
  }


    return (
       /* BE: protivate-campaign-footer class needs to add on "footer-wrap" dynamically only for protivate campaign pages footer*/
    <div className={`${footerClass} footer-wrap` + (showProtivateBox ? ' protivate-campaign-footer' : '')}>
      {location.pathname !== "/reps-retailers" &&
        location.pathname !== "/find-a-rep" &&
        location.pathname !== "/find-a-rep-ca" &&
        location.pathname !== "/knowledge-center" &&
        currentRegion != 4 && <FindRep />}
      <div className="footer-connect-wrap">
        <div className="footer-connect-angle">&nbsp;</div>
              <div className="footer-connect-center">
                  {showProtivateBox &&
                      <div className="footer-connect-flex ">
                          <div className="protivate-campaign-footer-box">
                              <img src={currentRegion === 3 ? protivateImgCa : protivateImg} alt="protivate product" className="protivate-img" />
                              <p className="protivate-product-text">Learn more about the nutritional seed enhancer, PROTIVATE.</p>
                              <button type="button" className="default-button default-button--large default-button--secondary"
                                  onClick={() =>
                                      currentRegion === 3
                                          ? navigate('/protivate-ca')
                                          : navigate('/protivate')
                                  }
                              >More Product Info </button>
                          </div>
                      </div>}
          <div className="footer-connect-flex">
            <div className="footer-connect-link" onClick={() => currentRegion === 2 ? navigate('/contact') : navigate('/contact-ca')} style={{cursor:'pointer'}}>
              <span className="footer-connect-icon message">Contact Us</span>
              <span className=""></span>
            </div>
            <div className="footer-connect-link" onClick={() => currentRegion === 2 ? navigate('/find-a-rep') : navigate('/find-a-rep-ca')} style={{cursor:'pointer'}}>
              <span className="footer-connect-icon message person">
                Find a Rep or Retailer
              </span>
              <span className=""></span>
            </div>
          </div>
          <div className="footer-connect-flex">
            <p className="footer-social-text">
              Connect with Koch Agronomic Services
            </p>
            <a className="footer-social-icon" href="https://www.facebook.com/KochAgronomicServices/" target="_blank">
              <img src={FacebookIcon} alt="Facebook Icon" width="10" height="20"/>
            </a>
            <a className="footer-social-icon" href="https://www.linkedin.com/company/koch-agronomic-services/" target="_blank">
              <img src={Linkdein} alt="Linkdein Icon" width="20" height="20"/>
            </a>
            <a className="footer-social-icon" href="https://twitter.com/kochagservices" target="_blank">
              <img src={TwitterIcon} alt="Twitter Icon" width="20" height="18"/>
            </a>
            <a className="footer-social-icon" href="https://www.youtube.com/channel/UCZFzMJDYjFq7h6kzsBVjH7Q" target="_blank">
              <img src={YouTubeIcon} alt="YouTube Icon" width="20" height="16"/>
            </a>
          </div>
        </div>
        <img
          className="footer-connect-logo"
          src={KochLogoFooter}
          alt="Koch logo"
        />
      </div>
      <div className="footer-center">
        <div className="footer-link-wrap" role="navigation">
          <FooterLinks productsUS={productsUS} productsCanada={productsCanada} currentRegionId={currentRegion}/>
          <div className="footer-link-column">
            <Link className="footer-link-heading" to="/knowledge-center">
              Knowledge Center
            </Link>
            <Link
              className="footer-link"
              to="/knowledge-center/category/38"
              state={{ shouldScrollToTop: false }} 
            >
              Podcasts
            </Link>
            <Link
              className="footer-link"
              to="/knowledge-center/category/40"
              state={{ shouldScrollToTop: false }} 

            >
              Research &amp; Reports
            </Link>
            <Link
              className="footer-link"
              to="/knowledge-center/category/39"
              state={{ shouldScrollToTop: false }} 
            >
              Articles
            </Link>
            <Link className="footer-link" to="/product-labels">
              Product Labels/SDSs
            </Link>
          </div>
          <div className="footer-link-column">
            <Link className="footer-link-heading" to="/about">
              About
            </Link>
            <Link className="footer-link" to="/press-releases">
              Press Releases
            </Link>
            <a className="footer-link" href="https://jobs.kochcareers.com/" target="_blank">Careers</a>
          </div>
          <div className="footer-link-column">
            <Link className="footer-link-heading" to={currentRegion === 2 ? "/find-a-rep" : "/find-a-rep-ca"}>
              Retailers &amp; Reps
            </Link>
            <Link className="footer-link" to={currentRegion === 2 ? "/find-a-rep" : "/find-a-rep-ca"}>
              Find a Rep or Retailer
            </Link>
          </div>
          <RegionSelector />
          <div className="footer-logo">
            <img src={KochLogo} alt="Koch Logo"  width="278" height="90"/>
          </div>
        </div>
      <FooterCopyRight />
      </div>
    </div>
  );
}

export default Footer;


const FooterCopyRight = () =>{
  const currentYear = new Date().getFullYear();

  return(
    <div className="footer-copyright-wrap" role="contentinfo">
    <p className="footer-copyright-text">
      <a href="https://privacypolicy.kochind.com/" target="_blank">Privacy Policy</a>&ensp;&middot;&ensp;
      <span>
        Do Not Sell or Share My Personal Information
      </span>
      &ensp;&middot;&ensp;
      <a href="https://www.kochind.com/california-supply" target="_blank">California Supply Chain Transparency</a>
      &ensp;&middot;&ensp;
      <a href="/doccenter/75dc105e-6b34-49df-8840-90426ef1ec2c">Modern Slavery Statement</a>
      &ensp;&middot;&ensp;<a href="https://www.kochind.com/terms-of-use/" target="_blank">Terms of Use</a>
      &ensp;&middot;&ensp;<a href="/doccenter/56bbfb31-a605-420c-b867-aae3600e4cee" >Patent Information</a>
    </p>
    <p className="footer-copyright-text">
      {/*AGROTAIN&trade;, the AGROTAIN&trade; logo, ANVOL&trade;, the ANVOL&trade;*/}
      {/*logo, CENTURO&trade;, the CENTURO&trade; logo, N-TEGRATION&trade;, the*/}
      {/*N-TEGRATION&trade; logo, SUPERU&trade;, and the SUPERU&trade; logo, HYDRO*/}
      {/*BULLET&trade;, the HYDRO BULLET&trade; logo,*/}
      {/*BATTALION&trade;, PROTIVATE&trade;, the PROTIVATE&trade; logo, MOLY*/}
      {/*SHINE&trade;, WOLF TRAX&trade;, the WOLF TRAX&trade; logo,*/}
      {/*3-TRAX&trade;, CROPMIX&trade;, EVENCOAT&trade;, NU-TRAX*/}
      {/*P+&trade;, the NU-TRAX P+&trade; logo, PROTINUS&trade; Seed Nutrition,*/}
      {/*the PROTINUS&trade; Seed Nutrition logo are trademarks of Koch*/}
      {/*Agronomic Services, LLC. Koch, the Koch logo and Principle Based*/}
      {/*Management&trade; are trademarks of Koch Industries, Inc. &copy;*/}
      {/*2023 Koch Agronomic Services, LLC.*/}

      AGROTAIN&trade;, the AGROTAIN logo, ANVOL&trade;, the ANVOL logo, CENTURO&trade;, the CENTURO logo, 
      ARMOUR&trade;, the ARMOUR logo, OPTRIENT&trade;, the OPTRIENT logo, N-TEGRATION&trade;, 
      the N-TEGRATION logo, SUPERU&trade;, and the SUPERU logo, TRIBUNE&trade;, the TRIBUNE logo,  
      SYNTHOS&trade;, the SYNTHOS logo, NUTRICARE&trade;, the NUTRICARE logo, WOLF TRAX&trade;, 
      the WOLF TRAX logo, PROTIVATE&trade;, the 
      PROTIVATE logo, 3-TRAX&trade;, DDP&trade;, CROP MIX&trade;, NU-TRAX&trade; and EVENCOAT&trade; 
      are trademarks of Koch Agronomic Services, LLC. Koch, the Koch logo and Principle Based 
      Management&trade; are trademarks of Koch Industries, Inc. © {currentYear} Koch Agronomic Services, LLC.
    </p>
    <p className="footer-copyright-text citation-impect">
      1. EPA EEF Challenge Submission data: 30% reduction - corn (Chatterjee et al, 2016); 34% reduction - corn 
       (Chatterjee et al, 2016); 45% reduction - corn (Yang et al, 2016); 35% reduction - corn (Eagle et al, 2017); 
       48% reduction - irrigated no-till corn (Halvorson et al, 2010); 53% reduction - semi-arid irrigated corn 
       (Dugan et al, 2017); 43.8% reduction - sandy loam soil (Awale and Chatterjee, 2017).
    </p>
    <p className="footer-copyright-text">
        The data and material contained herein are provided for informational purposes only. 
        No warranty, express or implied, is made including, but not limited to, implied 
        warranties of merchantability and fitness for a particular purpose, which are 
        specifically excluded. Results may vary based on a number of factors, including 
        environmental conditions. Before use, consult the product packaging and labeling for 
        information regarding the product's characteristics, uses, safety, efficacy, hazards 
        and health effects.
    </p>
    <p className="footer-copyright-text">Not all products may be available in your jurisdiction.</p>
    <p className="footer-copyright-text">
       CENTURO is not registered for sale or use in all jurisdictions. In the United States, 
       contact your state pesticide regulatory agency to determine if a product is registered 
       for sale or use in your state. Results may vary based on a number of factors, including 
       environmental conditions. Improvements in nutrient use efficiency, yield and nitrate 
       leaching may not be observed in all cases. 
    </p>
    <p className="footer-copyright-text">
       PROTIVATE NU5-LUX is not available for sale, use or distribution in California. 
    </p>
    <p  className="footer-copyright-text">
       Select KAS branded PROTIVATE products are pending registration for sale, use and 
       distribution in Canada.
    </p>
  </div>
  )
}

const FooterLinks = ({ currentRegionId, productsCanada, productsUS }) => {
  const productLinks = currentRegionId === 2 ? productsUS : currentRegionId === 3 ? productsCanada : null;

  return (
    <div className="footer-link-column">
      {productLinks && (
        <>
        <span className="footer-link-heading">
              Solutions
        </span>
          {Object.entries(productLinks).map(([productName, productUrl]) => (
            <Link key={productName} className="footer-link" to={productUrl}>
              {productName}&trade;
            </Link>
          ))}
      </>
      )}
    </div>
  );
};
