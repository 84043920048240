import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import { localstore } from '../utils/localForageStorage';
import logger from 'redux-logger';
import regionReducer from './region'; 

import authReducer from './auth';

const authPersistConfig = {
	key: 'root',
	storage: localstore
}

const rootReducer = combineReducers({
	auth: persistReducer(authPersistConfig, authReducer),
	region: regionReducer,
})

export const store = configureStore({
    reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: true,
			serializableCheck: false,
		}).concat(logger),
	devTools: process.env.NODE_ENV !== "production",
});

export const persistor = persistStore(store)
