import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './ResearchSlider.css';
import ProtivateChart1 from '../../assets/protivateca-graph.jpg';
import ProtivateChart2 from '../../assets/protivateca-vigor.jpg';
import ProtivateChart3 from '../../assets/protivateca-graphite.jpg';

export default function ResearchSlider() {
  var settings = {
    arrows: true,
    centerMode: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth:true,
  };
  return (
    <div className="research-slide-wrap">
      <Slider {...settings}>
              {/* Protivate */}
              <div className="research-rotator-slide protivate">
                  <span className="research-rotator-slide-title">Increased Corn Yield</span>
                  <span className="research-rotator-slide-text">
                      PROTIVATE showed positive ROI in 14 of 19 trials over a 5-year timeframe with an average corn grain yield increase of 4 bushels per acre. 
                  </span>
                  <span className="research-rotator-slide-image">
                     <img src={ProtivateChart1} alt="chart showing Dribble-Banded CENTURO Treated UAN Improved Corn Yield" width="635" height="410"/>
                  </span>
                  <span className="product-card-disclaimer">The underlying data was provided by SGS North America under a Research Trial Financial Support Agreements with Koch Agronomic Services, LLC. Neither the universities, institutions, nor the individual researchers referenced, endorse or recommend any product or service. Improvements in yield and nutrient use efficiency may not be observed in all cases.</span>
              </div>
              <div className="research-rotator-slide protivate">
                  <span className="research-rotator-slide-title">Increased Corn Seedling Vigor</span>
                  <span className="research-rotator-slide-text">
                      PROTIVATE increased corn seedling vigor from 4.25% in a standard saturated cold test.
                  </span>
                  <span className="research-rotator-slide-image">
                      <img src={ProtivateChart2} alt="chart showing Dribble-Banded CENTURO Treated UAN Improved Corn Yield" width="627" height="416" />
                  </span>
                  <span className="product-card-disclaimer">The underlying data was provided by SGS North America under a Research Trial Financial Support Agreements with Koch Agronomic Services, LLC. Neither the universities, institutions, nor the individual researchers referenced, endorse or recommend any product or service. Improvements in yield and nutrient use efficiency may not be observed in all cases.</span>
              </div>
              <div className="research-rotator-slide protivate">
                  <span className="research-rotator-slide-title">Increased Corn Yield</span>
                  <span className="research-rotator-slide-text">Across four locations,PROTIVATE provided a 4 bu/acre increase over talc/graphite alone.</span>
                  <span className="research-rotator-slide-image">
                      <img src={ProtivateChart3} alt="chart showing Dribble-Banded CENTURO Treated UAN Improved Corn Yield" width="628" height="397" />
                  </span>
                  <span className="product-card-disclaimer">The underlying data was provided by AgRevival. Agrithority, Michigan Ag Commodities, Alpha Ag Research, SGS North America, Stoneville R&D, and Tidewater Agronomics under a Research Trial Financial Support Agreements with Koch Agronomic Services, LLC. Neither the universities, institutions, nor the individual researchers referenced, endorse or recommend any product or service. Improvements in yield and nutrient use efficiency may not be observed in all cases.</span>
              </div>
      </Slider>
    </div>
  );
}