import React, { useState } from 'react';
import './StepForm.css';
import StepFormClose from '../../assets/step-form-close.svg';
import { Button } from '../Button/Button.jsx';

export const StepFour = ({ onNext, onPrev, step }) => {
    const [canNext, setCanNext] = useState(step.steps[step.step] != null);
    const [selected, setSelected] = useState(step.steps[step.step] != null ? step.steps[step.step] : []);
    const next = () => {
        onNext(selected);
    };

    const showNext = (value) => {
        const i = selected.indexOf(value);
        if (i >= 0) {
            var sel = selected.map(a => a);
            sel.splice(i, 1);
            setSelected(sel);
        } else {
            setSelected([...selected, value]);
        }
        setCanNext(true);
    }
 
    return (
        <section className={'stepForm'}>

            <div className="stepOne-formWrapper">
             <div className="mega-menu-container">
                <div className="mega-menu-container-inner">
                <p className="stepForm-questionList">Question 
                   {/*{step.step}*/}
                </p>
                <p className="stepForm-question">What are you deficient in? </p>
                <div className="radio-btn-row">
                    <label className="step-form-radio">
                        <input type="checkbox" className="radio-btn" name="cropStage" onChange={() => { showNext(1); }} checked={selected.indexOf(1) >= 0} />
                        <span className="step-form-label">Zinc</span>
                    </label>
                    <label className="step-form-radio">
                        <input type="checkbox" className="radio-btn" name="cropStage" onChange={() => { showNext(2); }} checked={selected.indexOf(2) >= 0} />
                        <span className="step-form-label">Boron</span>
                    </label>
                    <label className="step-form-radio">
                        <input type="checkbox" className="radio-btn" name="cropStage" onChange={() => { showNext(3); }} checked={selected.indexOf(3) >= 0} />
                        <span className="step-form-label">Copper</span>
                    </label>
                    <label className="step-form-radio">
                        <input type="checkbox" className="radio-btn" name="cropStage" onChange={() => { showNext(4); }} checked={selected.indexOf(4) >= 0} />
                        <span className="step-form-label">Iron</span>
                    </label>
                    <label className="step-form-radio">
                        <input type="checkbox" className="radio-btn" name="cropStage" onChange={() => { showNext(5); }} checked={selected.indexOf(5) >= 0} />
                        <span className="step-form-label">Manganese</span>
                    </label>
                    <label className="step-form-radio">
                        <input type="checkbox" className="radio-btn" name="cropStage" onChange={() => { showNext(6); }} checked={selected.indexOf(6) >= 0} />
                        <span className="step-form-label">Magnesium</span>
                    </label>
                    <label className="step-form-radio">
                        <input type="checkbox" className="radio-btn" name="cropStage" onChange={() => { showNext(7); }} checked={selected.indexOf(7) >= 0} />
                        <span className="step-form-label">Calcium</span>
                    </label>
                    <label className="step-form-radio">
                        <input type="checkbox" className="radio-btn" name="cropStage" onChange={() => { showNext(8); }} checked={selected.indexOf(8) >= 0} />
                        <span className="step-form-label">Blend of Nutrients</span>
                    </label>
                </div>
                <div>
                    <p className="stepform-message">Select all that apply</p>
                </div>
               </div>
              </div>
            </div>
            <div className="stepForm-btnWrapper">
                <Button onClick={onPrev} className="default-btn back-default-btn"
                    label="Back" />
                {canNext &&
                    <Button onClick={next} className="default-btn ml-auto"
                    label="Next" />
                }
            </div>
        </section>
  );
};