import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatAMPM } from '../../../utils/datetime';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
export default function EditPageWrapper({ itemId, route, title, children, versionItems, onCancel, onSave, rollBackRoute }) {
    const homeRoute = route;
    const navigate = useNavigate();

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link className="admin-top-bar-back" to={homeRoute}>
                        Back
                    </Link>
                </div>
                <div className="admin-top-bar-right">
                    {itemId && versionItems && versionItems.length > 0 && <>
                        <div className="rollbackWrap">
                            <div className="topBarLink"><span className="fa fa-mail-reply"></span>Rollback</div>
                            <div className="rollbackDropdown">
                                <SimpleBar style={{maxHeight: 175}}>    
                                {versionItems.map(version => {
                                    var date = new Date(Date.parse(version.created + 'Z'));
                                    return (
                                        <a key={version.id} href={`/siteadmin/${rollBackRoute}/edit/${itemId}/${version.id}`}>
                                            {date.toLocaleDateString("en-us", { year: "numeric", month: "long", day: "numeric" }) + ' ' + formatAMPM(date)}
                                        </a>);
                                })}
                                </SimpleBar>
                            </div>
                        </div>
                    </>}
                </div>
            </div>
            <div className="admin-page-content">
                <div className="admin-page-title-wrap">
                    <h1 className="admin-page-heading">{title} {itemId == null ? "Add" : "Edit"}</h1>
                </div>
                <div className="admin-page-content">
                    {children}
                </div>
                <div className="admin-floating-button-wrap">
                    <button
                        className="admin-floating-button"
                        onClick={() => { if (onCancel) onCancel() }}>
                        Cancel
                    </button>
                    <button
                        className="admin-floating-button power"
                        onClick={() => { if (onSave) onSave() }}
                    >
                        Save Changes
                    </button>
                </div>
            </div>
        </div>
    )
}


EditPageWrapper.PropType = {
    itemId: PropTypes.int,
    route: PropTypes.string,
    title: PropTypes.string,
    children: PropTypes.element,
    versonItems: PropTypes.array,
    onSave: PropTypes.func,
    onCancel: PropTypes.func
}

EditPageWrapper.defaultProps = {
 versionItems: []
}