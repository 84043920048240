import React, { useState } from 'react';
import './StepForm.css';
import StepFormClose from '../../assets/step-form-close.svg';
import { Button } from '../Button/Button.jsx';

export const StepTwo = ({ onNext, onPrev, step }) => {
    const [canNext, setCanNext] = useState(step.steps[step.step] != null);
    const [selected, setSelected] = useState(step.steps[step.step]);
    const next = () => {
        onNext(selected);
    };

    const showNext = (value) => {
        setSelected(value);
        setCanNext(true);
    }
    return (
        <section className={'stepForm'}>
            <div className="stepOne-formWrapper">
             <div className="mega-menu-container">
               <div className="mega-menu-container-inner">
                <p className="stepForm-questionList">Question 
                  {/*{step.step}*/}
                </p>
                <p className="stepForm-question">How would you like to apply your nutrients?</p>
                <div className="radio-btn-row">
                    {step.steps[1] != 7 &&
                        <label className="step-form-radio">
                            <input type="radio" className="radio-btn" name="cropStage" id="cropStage" onChange={() => { showNext(1); }} checked={selected == 1} />
                            <span className="step-form-label">
                                Granular Fertilizers
                                <span className="discription-label">
                                    Our micronutrient solutions can be incorporated for broader and more even spread
                                    patterns in granular fertilizers such as urea. This allows applicators and growers
                                    to cover more acres in less time while providing plants with the micronutrients
                                    they need to thrive.
                                </span>
                            </span>
                        </label>
                    }
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" id="cropStage" onChange={() => {showNext(2);}} checked={selected == 2} />
                        <span className="step-form-label">Seed Enhancer
                            <span className="discription-label">
                                Koch Agronomic Services' seed enhancement products provide the right nutrients
                                early in the crop's growth cycle. This allows plants to access what they need,
                                when they need it - during a difficult time of year - and be better set for early
                                growth.
                            </span>
                        </span>
                    </label>
                        </div>
                    </div>
                </div>
            </div>
                <div className="stepForm-btnWrapper">
                    <Button onClick={onPrev} className="default-btn back-default-btn"
                    label="Back" />

                    {canNext &&
                        <Button onClick={next} className="default-btn ml-auto"
                            label="Next" />
                    }
                </div>
        </section>
  );
};