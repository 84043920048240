import React, { useState } from 'react';
import './StepForm.css';
import StepFormClose from '../../assets/step-form-close.svg';
import { Button } from '../Button/Button.jsx';

export const StepFive = ({ onNext, onPrev, step }) => {
    const [canNext, setCanNext] = useState(step.steps[step.step] != null);
    const [selected, setSelected] = useState(step.steps[step.step]);
    const next = () => {
        onNext(selected);
    };

    const showNext = (value) => {
        setSelected(value);
        setCanNext(true);
    }
    return (
        <section className={'stepForm'}>
            <div className="stepOne-formWrapper">
             <div className="mega-menu-container">
                <div className="mega-menu-container-inner">
                <p className="stepForm-questionList">Question 
                    {/*{step.step}*/}
                </p>
                <p className="stepForm-question">Will you be treating on-farm/at the planter?</p>
                <div className="radio-btn-row">
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(1); }} checked={selected == 1} />
                        <span className="step-form-label">Yes</span>
                    </label>
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(2); }} checked={selected == 2} />
                        <span className="step-form-label">No</span>
                    </label>
                </div>
                    </div>
                </div>
            </div>
            <div className="stepForm-btnWrapper">
                <Button onClick={onPrev} className="default-btn back-default-btn"
                    label="Back" />
                {canNext &&
                    <Button onClick={next} className="default-btn ml-auto"
                    label="Next" />
                }
            </div>
        </section>
  );
};