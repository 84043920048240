import FullPageAjaxLoader from "./FullPageAjaxLoader";
import Navigation from "./Navigation/Navigation";
import Footer from "./Footer/Footer";
export default function SuspenseLoadingSpinner() {
  return (
    <>
      <Navigation />
      <FullPageAjaxLoader show={true} />
      {/* <Footer /> */}
    </>
  );
}
