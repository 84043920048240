import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
/*import '../styles/account-layout.css';*/
import { useNavigate } from 'react-router-dom';
import LoginLogo from '../assets/admin/accountImagemakers.png';
import './AccountLayout.css';
export default function DefaultLayout({ children }) {

    const navigate = useNavigate();

    useEffect(() => { }, []);

  return (
    <div className="adminAccountWrap">
    <div className="adminAccountContent">
      <a className="accountImagemakers" href="http://imagemakers-inc.com" title="Click to visit imagemakers-inc.com">
        <img src={LoginLogo} alt="Imagemakers" />
      </a>
      {children}
      </div>
    </div>
  );
}

DefaultLayout.propTypes = {
    children: PropTypes.any,
};
