import React, { useState } from 'react';
import './StepForm.css';
import StepFormClose from '../../assets/step-form-close.svg';
import { Button } from '../Button/Button.jsx';

export const StepSix = ({ onNext, onPrev, step }) => {
    const [canNext, setCanNext] = useState(step.steps[step.step] != null);
    const [selected, setSelected] = useState(step.steps[step.step]);
    const next = () => {
        onNext(selected);
    };

    const showNext = (value) => {
        setSelected(value);
        setCanNext(true);
    }
    return (
        <section className={'stepForm'}>
            <div className="stepOne-formWrapper">
             <div className="mega-menu-container">
                <div className="mega-menu-container-inner">
                <p className="stepForm-questionList">Question 
                    {/*{step.step}*/}
                </p>
                <p className="stepForm-question">What nitrogen source are you applying?</p>
                <div className="radio-btn-row">
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" id="cropStage" onChange={() => { showNext(1); }} checked={selected == 1} />
                        <span className="step-form-label">
                            Urea
                            <span className="discription-label">
                                Up to 40% of surface-applied, unincorporated urea-based fertilizer can be lost through volatilization.
                                Our urease inhibitor solutions help slow this process.
                            </span>
                        </span>
                    </label>
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" id="cropStage" onChange={() => { showNext(2); }} checked={selected == 2} />
                        <span className="step-form-label">UAN
                            <span className="discription-label">
                                If you're applying UAN without a stabilizer, you're risking nutrient loss.
                                Utilize a proven nitrogen stabilizer to keep applied nitrogen in the ammonium form longer.
                            </span>
                        </span>
                    </label>
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" id="cropStage" onChange={() => { showNext(3); }} checked={selected == 3} />
                        <span className="step-form-label">Anhydrous Ammonia
                            <span className="discription-label">
                                Growers who apply anhydrous ammonia need to be proactive in protecting it from leaching and denitrification.
                                Our reliable nitrification inhibitors can help.
                            </span>
                        </span>
                    </label>
                </div>
               </div>
             </div>
            </div>
            <div className="stepForm-btnWrapper">
                <Button onClick={onPrev} className="default-btn back-default-btn"
                    label="Back" />

                {canNext &&
                    <Button onClick={next} className="default-btn ml-auto"
                        label="Next" />
                }
            </div>
        </section>
    );
};