import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./forms.css";
import { filter as filterApi, archive as archiveApi } from "../../../api/formsApi";
import { formatDate } from "../../../utils/datetime";
export default function Forms() {
    const [filter, setFilter] = useState({ start: 1, length: 20, order: 4, status: -1 });
    const [items, setItems] = useState([]);
    const [shouldDelete, setShouldDelete] = useState(null);
    const [start, setStart] = useState(1);
    const [sort, setSort] = useState({ field: "published", direction: "desc" });
    const [message, setMessage] = useState(null);

    const handleSort = (field) => {
        const isAsc = sort.field === field && sort.direction === "asc";
        setSort({ field: field, direction: isAsc ? "desc" : "asc" });
        var order = 0;
        if (field == 'name') { order = 0; }
        if (field == 'email') { order = 1; }
        if (field == 'created') { order = 2; }
        if (field == 'type') { order = 3; }
        order = order * 2;
        if (!isAsc) { order = order + 1; }
        setFilter({ ...filter, order: order });
    };

    useEffect(() => {
        filterApi(filter).then((results) => {
            // if (results.data.success == true) {
            //     // setItems(results.data.data);
            //     const parsedData = JSON.parse(results.data.data.data);
            //     setItems(parsedData);
            // }

            if (results.data.success === true) {
                const parsedItems = results.data.data.map((item) => {
                    return {
                        ...item,
                        dataParsed: safeJsonParse(item.data) 
                    };
                });
                setItems(parsedItems);
            }
        });
    }, [filter]);

    const handleDeleted = (id) => {
        setShouldDelete(null);
        archiveApi(id).then(() => {
            setFilter({ ...filter, updated: 1 * Math.round(999 * Math.random()) });
            setStart(1);
        });
    };

    const loadMore = () => {
        filterApi({ ...filter, start: start + filter.length }).then((results) => {
            if (results.data.success == true) {
                const additionalItems = results.data.data.map(item => ({
                    ...item,
                    dataParsed: safeJsonParse(item.data)
                }));
                setItems([...items, ...additionalItems]);
            }
        });
        setStart(start + filter.length);
    };
    function safeJsonParse(str, defaultValue = {}) {
        try {
            const parsed = JSON.parse(str);
    
            if ('Zipcode' in parsed && typeof parsed.Zipcode === 'string' && parsed.Zipcode.trim() !== '') {
                return parsed;
            } else {
                return { ...defaultValue, Zipcode: '' };
            }
        } catch (e) {
            return '';
        }
    }

    function capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    
    return (
        <>
            {shouldDelete != null && (
                <div className="admin-modal-overlay">
                    <div className="admin-modal admin-add-role">
                        <div className="admin-modal-scroll">
                            <button
                                className="admin-modal-close"
                                onClick={() => {
                                    setShouldDelete(null);
                                }}
                            >
                                <span className="material-symbols-outlined">close</span>
                            </button>
                            <h2 className="admin-modal-heading">
                                Archive the Form written by "{shouldDelete.name}" ?
                            </h2>
                            <div className="admin-buttons">
                                <button
                                    className="admin-floating-button"
                                    onClick={() => {
                                        setShouldDelete(null);
                                    }}
                                >
                                    Cancel
                                </button>
                                <button
                                    className="admin-floating-button power"
                                    onClick={() => handleDeleted(shouldDelete.id)}
                                >
                                    Yes
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {message != null && (
                <div className="admin-modal-overlay">
                    <div className="admin-modal admin-add-role">
                        <div className="admin-modal-scroll">
                            <button
                                className="admin-modal-close"
                                onClick={() => {
                                    setMessage(null);
                                }}
                            >
                                <span className="material-symbols-outlined">close</span>
                            </button>
                            <h2 className="admin-modal-heading">
                                Written by {message.name}
                            </h2>
                            <table><tbody>
                                <tr><td><b>Phone:</b></td><td>{message.phone}</td></tr>
                                {Object.keys(JSON.parse(message.data)).map(a => {
                                    return (<tr><td><b>{capitalize(a)}:</b></td><td>{JSON.parse(message.data)[a]}</td></tr>);
                                })}
                            </tbody></table>
                            <>
                            
                            </>
                            
                        </div>
                    </div>
                </div>
            )}
            <div className="admin-page-wrap">
                <div className="admin-top-bar-wrap">
                    <div className="admin-top-bar-left">
                        <Link className="admin-top-bar-back" to="/siteadmin/">
                            Back
                        </Link>
                    </div>
                </div>
                <div className="admin-page-title">
                    <div className="admin-page-title-wrap">
                        <h1 className="admin-page-heading">Forms</h1>
                    </div>
                </div>
                <div className="admin-page-content">
                    <table className="default-table">
                        <thead>
                            <tr>
                                <th onClick={() => handleSort("name")}>
                                    Name
                                    <span className="sort-btn">
                                        {sort.field === "name" ?
                                            (sort.direction === "asc" ? (
                                                <span className="material-symbols-outlined">
                                                    keyboard_arrow_up
                                                </span>
                                            ) : (
                                                <span className="material-symbols-outlined">
                                                    keyboard_arrow_down
                                                </span>
                                            )) :
                                            <span className="material-symbols-outlined">
                                                unfold_more
                                            </span>
                                        }
                                    </span>
                                </th>
                                <th onClick={() => handleSort("type")}>
                                    Type
                                    <span className="sort-btn">
                                        {sort.field === "type" ?
                                            (sort.direction === "asc" ? (
                                                <span className="material-symbols-outlined">
                                                    keyboard_arrow_up
                                                </span>
                                            ) : (
                                                <span className="material-symbols-outlined">
                                                    keyboard_arrow_down
                                                </span>
                                            )) :
                                            <span className="material-symbols-outlined">
                                                unfold_more
                                            </span>
                                        }
                                    </span>
                                </th>
                                <th onClick={() => handleSort("created")}>
                                    Created
                                    <span className="sort-btn">
                                        {sort.field === "created" ?
                                            (sort.direction === "asc" ? (
                                                <span className="material-symbols-outlined">
                                                    keyboard_arrow_up
                                                </span>
                                            ) : (
                                                <span className="material-symbols-outlined">
                                                    keyboard_arrow_down
                                                </span>
                                            )) :
                                            <span className="material-symbols-outlined">
                                                unfold_more
                                            </span>
                                        }
                                    </span>
                                </th>
                                <th>Phone</th>
                                <th>ZipCode</th>

                                <th>&nbsp;</th>
                            </tr>
                        </thead>
                        <tbody>
                            {items.length > 0 &&
                                items.map((item) => {
                                    var type;
                                    switch (item.type) {
                                        case 1: type = 'Rep'; break;
                                        default: type = 'Contact'; break;
                                    }
                                    return (
                                        <tr>
                                            <td>{item.name}</td>
                                            <td>{type}</td>
                                            <td>{formatDate(new Date(Date.parse(item.created)))}</td>
                                            <td>{item.phone}</td>
                                            <td>{item.dataParsed.Zipcode}</td>
                                            <td className="table-options">
                                                <div
                                                    onClick={() => setMessage(item) }
                                                    style={{cursor:'pointer'}}
                                                >
                                                    View
                                                </div>
                                                {/* <div
                                                    onClick={() => setShouldDelete(item) }
                                                    className="material-symbols-outlined"
                                                    style={{ cursor: "pointer" }}
                                                    title="Archive form"
                                                >
                                                    archive
                                                </div> */}
                                            </td>
                                        </tr>
                                    );
                                })}
                        </tbody>
                    </table>
                    {items.length >= filter.length + start - 1 && (
                        <div className="admin-buttons">
                            <button className="admin-button" onClick={loadMore}>
                                Load More...
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
