import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parseHtml from '../../../utils/parseHtml';

export default function TwoColumnTextModule({ module }) {

  const [moduleData, setModuleData] = useState(module);

  useEffect(() => {
    setModuleData(module);
  }, [module]);

  if (moduleData != null && moduleData.content !== null)
    return (
      <div className="knowledge-roi-wrap">
        {moduleData.content && moduleData.content.map((content, idx) =>
          <div className="knowledge-roi-item" key={idx}>
                {content.headline != '' && <p className="headline">{content.headline}</p>}
            <p>{parseHtml(content.shortDescription.replaceAll('\n','<br />'))}</p>
          </div>
        )}
        </div>
    );
  else return null;
}

TwoColumnTextModule.PropType = {
  module: PropTypes.object
}