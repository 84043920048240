export const ResourceType = {
  Article: 0,
  Research: 1,
  Podcast: 2,
};
const ResourceName = Object.keys(ResourceType).reduce((acc, key) => {
    acc[ResourceType[key]] = key;
    return acc;
  }, {});
  
  export const getResourceTypeName = (id) => {
    return ResourceName[id] || 'Unknown';
  };