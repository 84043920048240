import { getResourceTypeName } from "../../models/ResourceType.js";
export const LatestResources = ({ resources }) => {
    return (
        <div className="knowledge-resource-nav">
            <div className="knowledge-resource-inner-wrap">
                <p className="knowledge-resource-item-heading">Latest Resources</p>
                {resources &&
                    resources.map((resource, index) => (
                        <a
                            key={index}
                            className="knowledge-resource-item"
                            href={resource.slug || "#"}
                            rel="noopener noreferrer"
                        >
                            <span className="knowledge-resource-item-inner">
                                {resource.heroMedia && (
                                    <span className="knowledge-resource-img">
                                        <img
                                            src={resource.heroMedia.previewsrc}
                                            alt={
                                                resource.heroMedia.altText || "Knowledge Placeholder"
                                            }
                                        />
                                    </span>
                                )}
                                <span className="knowledge-resource-detail-wrap">
                                    <span className="knowledge-resource-category">
                                        {getResourceTypeName(resource.resourceType)}
                                    </span>
                                    <span className="knowledge-resource-detail-title">
                                        {resource.title}
                                    </span>
                                    {resource.metadata && resource.metadata.desc && (
                                        <span className="knowledge-resource-detail-desc">
                                            {resource.metadata.desc}
                                        </span>
                                    )}
                                </span>
                                <span className="knowledge-resource-detail-arrow"></span>
                            </span>
                        </a>
                    ))}
            </div>
        </div>
    );
};