
import AccountLayout from '../layouts/AccountLayout.jsx';
import Login from '../pages/Account/login.js';
import Logout from '../pages/Account/logout.js';
import ForgotPassword from '../pages/Account/forgotpassword.js';
import ResetPassword from '../pages/Account/resetpassword.js';

export const accountRoutes = [
  {
    path: '/account/login',
    element: Login,
    layout: AccountLayout,
    secure: false
  },
  {
    path: '/account/logout',
    element: Logout,
    layout: AccountLayout,
    secure: false
  },
  {
    path: '/account/forgot-password',
    element: ForgotPassword,
    layout: AccountLayout,
    secure: false
  },
  {
    path: '/account/reset-password/:hash',
    element: ResetPassword,
    layout: AccountLayout,
    secure: false
  }
];
