export function formatDate(date) {
    return (date.getMonth() + 1) + '/' + date.getDate() + '/' + date.getFullYear();
}

export function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}


export function formatDateTime(dateInput) {
    const date = new Date(dateInput);

    const day = date.getDate();
    const month = date.getMonth() + 1; 
    const year = date.getFullYear();

    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours || 12; 
    minutes = minutes < 10 ? '0' + minutes : minutes;

    return `${month}/${day}/${year} ${hours}:${minutes} ${ampm}`;
}

export function getLocalTime(date) {
    return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
}
