import './Layout.css';
import { useSelector } from 'react-redux';
import AdminDrawer from '../components/admin/drawer';
// import { RegionProvider } from '../store/RegionContext';
import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop';
const Layout = ({ children }) => {
	const { isAuthenticated } = useSelector((state) => state.auth);
	//admin-menu
	return (
		<div className={"admin-full-wrap" + (isAuthenticated ? " admin-menu" : "")}>
			<ScrollToTop />
			{children}
		</div>
  )
};

export default Layout;