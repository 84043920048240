import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./MegaMenu.css";
import Optrient from "../../assets/OPTRIENT-TM.png";
import WolfTrax from "../../assets/WOLF-TRAX-TM.png";
import Agrotain from "../../assets/AGROTAIN-TM.png";
import Anvol from "../../assets/ANVOL-TM.png";
import Centuro from "../../assets/CENTURO-TM.png";
import Superu from "../../assets/SUPERU-TM.png";
import Protivate from "../../assets/PROTIVATE-TM.png";
import { Button } from "../Button/Button.jsx";

import ProductSelector from "./ProductSelector.js";
export const MegaMenu = ({ close, ClickProduct }) => {
  const [formStarted, setFormStarted] = useState(false);
  const navigate = useNavigate();
  //   const productShow = () => {
  //     // setFormStarted(true);
  //     navigate("product-selector");
  //   };

  return (
    <section className={"mega-menu-wrapper"}>
      <div className={"mega-menu-container"}>
        {!formStarted ? (
          <div className={"menu-row"}>
            <div className={"column-4"}>
              <p className={"menu-card-title"}>Nutrient Management</p>
              <Link
                to={"/optrient"}
                className={"menu-card"}
                onClick={ClickProduct}
              >
                <img src={Optrient} alt="logo" className={"product-logo"} />
                <p className="menu-card-content">
                  OPTRIENT&trade; foliar nutrients can address deficiencies and
                  maximize performance by applying targeted nutrition.
                </p>
                <div className={"card-arrow-block orange"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#CE470D"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path
                      fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />{" "}
                  </svg>
                </div>
              </Link>
              <Link
                to={"/wolf-trax"}
                className={"menu-card"}
                onClick={ClickProduct}
              >
                <img src={WolfTrax} alt="logo" className={"product-logo"} />
                <p className="menu-card-content">
                    WOLF TRAX&trade; DDP&trade; micronutrients are field-proven to boost crop performance.
                </p>
                <div className={"card-arrow-block gray"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#CE470D"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path
                      fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />{" "}
                  </svg>
                </div>
              </Link>
            </div>

            <div className={"column-4 nutrient-column"}>
              <p className={"menu-card-title"}>Nutrient Protection</p>
              <Link
                to={"/agrotain"}
                className={"menu-card"}
                onClick={ClickProduct}
              >
                <img src={Agrotain} alt="logo" className={"product-logo"} />
                <p className="menu-card-content">
                  <b className={"d-block"}>Above Ground</b>
                  AGROTAIN&trade; nitrogen stabilizer is the most
                  research-proven urease inhibitor on the market.
                </p>
                <div className={"card-arrow-block green"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#CE470D"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path
                      fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />{" "}
                  </svg>
                </div>
              </Link>
              <Link
                to={"/anvol"}
                className={"menu-card"}
                onClick={ClickProduct}
              >
                <img src={Anvol} alt="logo" className={"product-logo"} />
                <p className="menu-card-content">
                  <b className={"d-block"}>Above Ground</b>
                  ANVOL&trade; nitrogen stabilizer will protect against
                  volatilization and provide superior flexibility.
                </p>
                <div className={"card-arrow-block green"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#CE470D"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path
                      fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />{" "}
                  </svg>
                </div>
              </Link>
              <Link
                to={"/centuro"}
                className={"menu-card"}
                onClick={ClickProduct}
              >
                <img src={Centuro} alt="logo" className={"product-logo"} />
                <p className="menu-card-content">
                  <b className={"d-block"}>Below Ground</b>
                      CENTURO&trade; nitrogen stabilizer offers highly-effective below-ground protection against leaching and denitrification.
                </p>
                <div className={"card-arrow-block purple"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#CE470D"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path
                      fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />{" "}
                  </svg>
                </div>
              </Link>
              <Link
                to={"/superu"}
                className={"menu-card"}
                onClick={ClickProduct}
              >
                <img src={Superu} alt="logo" className={"product-logo"} />
                <p className="menu-card-content">
                  <b className={"d-block"}>Above & Below Ground</b>
                  SUPERU&trade; premium fertilizer provides protection against all three forms of nitrogen loss.
                </p>
                <div className={"card-arrow-block blue"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#CE470D"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path
                      fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />{" "}
                  </svg>
                </div>
              </Link>
            </div>

            <div className={"column-4"}>
              <p className={"menu-card-title"}>Seed Enhancement</p>
              <Link
                to={"/protivate"}
                className={"menu-card"}
                onClick={ClickProduct}
              >
                <img src={Protivate} alt="logo" className={"product-logo"} />
                <p className="menu-card-content">
                    PROTIVATE&trade; seed enhancer provides seed lubricity and essential nutrients to support early and uniform crop emergence.
                </p>
                <div className={"card-arrow-block red"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#CE470D"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path
                      fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />{" "}
                  </svg>
                </div>
              </Link>

              <div className={"product-selector-wrapper"}>
                <p className={"crops-heading m-0"}>
                  <span className={"d-block"}>What Product is</span>
                  Right For Your Crops?
                </p>
                <p className={"crops-disc m-0"}>
                  {/*Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
                  {/*Integer vel semper elit.*/}
                </p>

                <div className={"product-select-btn-wrapper"}>
                  <Link
                    to={"/product-selector"}
                    state={{ internalRoute: true }}
                  >
                    <Button label="Product Selector" primary="true" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </section>
  );
};
