import React, { useState, useEffect, useRef } from "react";

export default function Submenu({ sections }) {
  const [activeSection, setActiveSection] = useState("");
  const sectionRefs = useRef(new Map());
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        let highestRatioEntry = null;
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            if (!highestRatioEntry || entry.intersectionRatio > highestRatioEntry.intersectionRatio) {
              highestRatioEntry = entry;
            }
          }
        });
  
        if (highestRatioEntry) {
          setActiveSection(highestRatioEntry.target.id);
        }
      },
      {
        rootMargin: '-50px 0px -50px 0px',
        threshold: buildThresholdList(), 
      }
    );
  
    sections.forEach((section) => {
      const sectionEl = document.getElementById(section.id);
      if (sectionEl) {
        observer.observe(sectionEl);
      }
    });
  
    return () => {
      sections.forEach((section) => {
        const sectionEl = document.getElementById(section.id);
        if (sectionEl) {
          observer.unobserve(sectionEl);
        }
      });
    };
  }, [sections]);
  
  // Helper function to create an array of thresholds
  function buildThresholdList() {
    let thresholds = [];
    for (let i=1.0; i<=100; i++) {
      let ratio = i/100;
      thresholds.push(ratio);
    }
    return thresholds;
  }
  
  const setRef = (section) => (el) => {
    if (el) {
      sectionRefs.current.set(section.id, el);
    } else {
      sectionRefs.current.delete(section.id);
    }
  };

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    //   setActiveSection(id);
        }
  };

  return (
      <div className="product-sticky-nav">
    <div className="product-submenu-wrapper">
      <div className="product-sticky-nav-link-wrap">
        {sections.map((section) => (
          <span
            key={section.id}
            className={`product-sticky-nav-link ${
              activeSection === section.id ? "active" : ""
            }`}
            onClick={() => scrollToSection(section.id)}
            ref={setRef(section)} 
          >
            {section.title}
          </span>
        ))}
              </div>
          </div>
    </div>
  );
}
