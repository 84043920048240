import React, { useState, useEffect, useRef } from "react";
import "./ContactOverlay.css";
import ContactClose from "../../assets/contact-close.svg";
import { useSelector } from "react-redux";
import { getAll } from "../../api/statesApi";
import { add } from "../../api/formsEuiApi";
import { IntlPhoneInput } from "../PhoneInput";
import { useNavigate } from "react-router-dom";

export default function ContactOverlay() {
    const region = useSelector((state) => state.region.currentRegion);
    const navigate = useNavigate();
    const [stateList, setStateList] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        data: { region: "", role: "" },
    });
    const [showSuccess, setShowSuccess] = useState(false);
    const [errors, setErrors] = useState({
        name: "",
        email: "",
        region: "",
        role: "",
    });

    const handleFormChange = (e) => {
        const { name, value } = e.target;
        if (name === "name" || name === "email" || name === "phone") {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        } else {
            setFormData((prevData) => ({
                ...prevData,
                data: {
                    ...prevData.data,
                    [name]: value,
                },
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;

        window.grecaptcha.ready(function () {
            window.grecaptcha.execute('6Lc1iEwpAAAAAAMTYulAYzcoWy35FuHiJoaz4hul', { action: 'form' }).then(function (token) {

                const submissionData = {
                    ...formData,
                    data: JSON.stringify(formData.data),
                    reCaptchaToken: token
                };
                try {
                    add(submissionData).then(response => {
                        if (response.data.success) {
                            setShowSuccess(true);
                        }
                    });
                } catch (error) {
                    // Handle error
                }
            });
        });
    };
    const validateForm = () => {
        let isValid = true;
        let newErrors = { name: "", email: "", region: "", role: "", captcha: "" };

        // Name validation
        if (!formData.name.trim()) {
            newErrors.name = "Name is required.";
            isValid = false;
        }

        // Email validation
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!formData.email.trim()) {
            newErrors.email = "Email is required.";
            isValid = false;
        } else if (!emailRegex.test(formData.email)) {
            newErrors.email = "Please enter a valid email address.";
            isValid = false;
        }

        // Region validation
        if (!formData.data?.region.trim()) {
            newErrors.region = "Region is required.";
            isValid = false;
        }

        // Role validation
        if (!formData.data?.role.trim()) {
            newErrors.role = "Role is required.";
            isValid = false;
        }

        setErrors(newErrors);
        if (!isValid) {
            scrollToFirstError();
        }
        return isValid;
    };
    const onClose = () => {
        if (region === 2) {
            navigate('/');
        }
        if (region === 3) {
            navigate('/home-ca');
        }
        if (region === 4) {
            navigate('/home-int');
        }
    }

    const scrollToFirstError = () => {
        for (const key in errors) {
            if (errors[key]) {
                const errorElement = document.getElementById(key); // Assuming error IDs are inputID + 'Error'
                if (errorElement) {
                    errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
                    break;
                }
            }
        }
    };


    useEffect(() => {
        getAll(region).then((response) => {
            if (response.data.success) {
                setStateList(response.data.data);
            }
        });
    }, [region]);

    useEffect(() => {
        document.documentElement.style.overflow = 'hidden';


        return () => {
            document.documentElement.style.overflow = '';
        };
    }, []);

    return (
        <div className="contact-overlay-wrap" id="content">
            {showSuccess ? (
                <div className="contact-overlay-inner">
                    <a className="contact-overlay-close" onClick={onClose} >
                        {" "}
                        <img src={ContactClose} alt="close" />{" "}
                    </a>
                    <div className="contact-submit-wrap">
                        <p className="contact-submit-text">
                            Thank you for messaging us. The Koch Agronomics team is reviewing
                            your request and working to connect you with the right resource to
                            answer your question.
                        </p>
                    </div>
                </div>
            ) : (
                <div className="contact-overlay-inner">
                    <a className="contact-overlay-close" onClick={onClose}>
                        {" "}
                        <img src={ContactClose} alt="close" />{" "}
                    </a>
                    <div className="contact-overlay-left">
                        <p className="contact-title">Connect with us</p>
                        <p className="contact-description">
                           The Koch Agronomic Services team is ready to help! Simply fill out the form 
                           and our team will connect you with the right resource.
                        </p>
                    </div>
                    <div className="contact-overlay-right">
                        <div className="contact-overlay-form-wrap">
                            <div className="contact-form-field-wrap">
                                <label className="contact-form-label" for="name">
                                    Name*
                                </label>
                                <input
                                    className="contact-form-input"
                                    id="name"
                                    name="name"
                                    type="text"
                                    required="true"
                                    value={formData.name}
                                    onChange={handleFormChange}
                                    aria-labelledby="name"
                                    aria-required="true"
                                />
                                {errors.name && (
                                    <div className="error-message">{errors.name}</div>
                                )}
                            </div>

                            <div className="contact-form-field-wrap">
                                <label className="contact-form-label">
                                    Phone
                                </label>
                                <IntlPhoneInput
                                    value={formData.phone}
                                    className="contact-form-input"
                                    id="phone"
                                    name="phone"
                                    type="text"
                                    required="true"
                                    onChange={(phone) => {
                                        handleFormChange({
                                            target: { name: "phone", value: phone },
                                        });
                                    }}
                                    aria-label="Phone Number"
                                />
                            </div>
                            <div className="contact-form-field-wrap">
                                <label className="contact-form-label" for="email">
                                    Email*
                                </label>
                                <input
                                    className="contact-form-input"
                                    id="email"
                                    name="email"
                                    type="email"
                                    required="true"
                                    value={formData.email}
                                    onChange={handleFormChange}
                                />
                                {errors.email && (
                                    <div className="error-message" id='email'>{errors.email}</div>
                                )}
                            </div>

                            <div className="contact-form-field-wrap">
                                <label className="contact-form-label" htmlFor="region">
                                    Region*
                                </label>
                                <select
                                    name="region"
                                    className="contact-overlay-form-select"
                                    onChange={handleFormChange}
                                    value={formData.data?.region}
                                    id="region"
                                >
                                    <option value=""></option>
                                    <option value="USA">USA</option>
                                    <option value="Canada">Canada</option>
                                    <option value="Central America">Central America</option>
                                    <option value="South America">South America</option>
                                    <option value="Europe">Europe</option>
                                    <option value="Asia Pacific">Asia Pacific</option>
                                    <option value="Africa">Africa</option>
                                </select>
                                {errors.region && (
                                    <div className="error-message" id='region'>{errors.region}</div>
                                )}
                            </div>

                            {region === 4 ? (
                                ""
                            ) : (
                                <div className="address-field-wrap">
                                    <div className="contact-form-field-wrap wid-60">
                                        <label className="contact-form-label" for="state">
                                            {region === 3 ? "Province" : "State"}
                                        </label>
                                        <select
                                            className="contact-overlay-form-select"
                                            onChange={handleFormChange}
                                                    name="state"
                                                    id="state"
                                        >
                                            <option value=""></option>
                                            {stateList.map((state) => (
                                                <option key={state.id} value={state.code}>
                                                    {state.name}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="contact-form-field-wrap wid-40">
                                        <label className="contact-form-label" for="zipcode">
                                            {region === 3 ? "Postal Code" : "Zip Code"}
                                        </label>
                                        <input
                                            className="contact-form-input"
                                            id="zipcode"
                                            name="zipcode"
                                            type="text"
                                            required=""
                                            onChange={handleFormChange}
                                        />
                                    </div>
                                </div>
                            )}

                            <div className="contact-form-field-wrap">
                                <label className="contact-form-label" htmlFor="role">
                                    What is your role?*
                                </label>
                                <select
                                    className="contact-overlay-form-select"
                                    onChange={handleFormChange}
                                    value={formData.data?.role}
                                    name="role"
                                    id="role"
                                >
                                    <option value=""></option>
                                    <option value="Grower">Grower</option>
                                    <option value="Product retailer">Product retailer</option>
                                    <option value="Vendor">Vendor</option>
                                    <option value="Other">Other</option>
                                </select>
                                {errors.role && (
                                    <div className="error-message" id='role'>{errors.role}</div>
                                )}
                            </div>

                            <div className="contact-form-field-wrap">
                                <label className="contact-form-label" for="message">
                                    How can we help you? (leave us a message)*
                                </label>
                                <textarea
                                    className="contact-form-textarea"
                                    id="message"
                                    name="message"
                                    type="text"
                                    required=""
                                    onChange={handleFormChange}
                                />
                  </div>
                  <div className="contact-form-field-wrap">
                    <span className="form-label-subtitle contact-form-label">
                      This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                    </span>
                  </div>
                            <div className="contact-form-button-row">
                                <button className="reps-cta" onClick={handleSubmit}>
                                    Send Message
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
