import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parseHtml from '../../../utils/parseHtml';
export default function TextCalloutModule({ module }) {

  const [moduleData, setModuleData] = useState(module);

  useEffect(() => {
    setModuleData(module);
  }, [module]);

  if (moduleData != null && moduleData.content !== null)
    return (
      <div className="knowledge-detail-testimonial">
            {parseHtml(moduleData.content)}
      </div>
    );
  else return null;
}

TextCalloutModule.PropType = {
  module: PropTypes.object
}