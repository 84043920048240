import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import AppRouter from './router/BaseRouter';
import { Provider } from 'react-redux';
import { store, persistor } from './store';

export default function App() {
    return (
      <>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <BrowserRouter>
                <AppRouter />
            </BrowserRouter>
          </PersistGate>
            </Provider>
        </>
    );
}
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);