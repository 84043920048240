import React, { useState } from 'react';
import './StepForm.css';
import StepFormClose from '../../assets/step-form-close.svg';
import { Button } from '../Button/Button.jsx';

export const StepThree = ({ onNext, onPrev, step }) => {
    const [isGranular, setGranular] = useState(step.steps[2] == 1 || step.steps[1] == 5 || step.steps[1] == 6);
    const [canNext, setCanNext] = useState(step.steps[step.step] != null);
    const [selected, setSelected] = useState(step.steps[step.step]);
    const next = () => {
        onNext(selected);
    };

    const showNext = (value) => {
        setSelected(value);
        setCanNext(true);
    }
    return (
        <section className={'stepForm'}>
            <div className="stepOne-formWrapper">
              <div className="mega-menu-container">
                <div className="mega-menu-container-inner">
                <p className="stepForm-questionList">Question 
                  {/*{step.step}*/}
                </p>
                <p className="stepForm-question">What crop are you growing?</p>
                <div className="radio-btn-row">
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(1); }} checked={selected == 1} />
                        <span className="step-form-label">Corn</span>
                    </label>
                    {!isGranular &&
                        <>
                            <label className="step-form-radio">
                            <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(2); }} checked={selected == 2} />
                                <span className="step-form-label">Soybean</span>
                            </label>
                            <label className="step-form-radio">
                            <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(3); }} checked={selected == 3} />
                                <span className="step-form-label">Peas</span>
                            </label>
                            <label className="step-form-radio">
                            <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(4); }} checked={selected == 4} />
                                <span className="step-form-label">Lentils</span>
                            </label>
                            <label className="step-form-radio">
                            <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(5); }} checked={selected == 5} />
                                <span className="step-form-label">Peanuts</span>
                            </label>
                            <label className="step-form-radio">
                            <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(6); }} checked={selected == 6} />
                                <span className="step-form-label">Alfalfa</span>
                            </label>
                    </>
                    }
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(7); }} checked={selected == 7} />
                        <span className="step-form-label">Wheat</span>
                    </label>
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(8); }} checked={selected == 8} />
                        <span className="step-form-label">Cotton</span>
                    </label>
                    {!isGranular &&
                        <>
                            <label className="step-form-radio">
                            <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(9); }} checked={selected == 9} />
                                <span className="step-form-label">Sunflowers</span>
                            </label>
                        </>
                    }
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(10); }} checked={selected == 10} />
                        <span className="step-form-label">Canola</span>
                    </label>
                    {isGranular &&
                        <>
                            <label className="step-form-radio">
                            <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(11); }} checked={selected == 11} />
                                <span className="step-form-label">Rice</span>
                            </label>
                            <label className="step-form-radio">
                            <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(12); }} checked={selected == 12} />
                                <span className="step-form-label">Other</span>
                            </label>
                        </>
                    }
                </div>
               </div>
              </div>
            </div>
            <div className="stepForm-btnWrapper">
                <Button onClick={onPrev} className="default-btn back-default-btn"
                    label="Back" />
                {canNext &&
                    <Button onClick={next} className="default-btn ml-auto"
                        label="Next" />
                }
            </div>
        </section>
  );
};