import axios from "axios";
import { store } from '../store';
import { getNewToken } from '../store/auth/actions';
import moment from 'moment';
import { jwtDecode } from 'jwt-decode';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let user = store.getState().auth;
    if (user.token != null) {
      const decoded = jwtDecode(user.token);
      if (decoded.exp && decoded.exp - moment().unix() < 10) {
          await store.dispatch(getNewToken({ refreshToken: user.refreshToken }));
          user = store.getState().auth;
      }
      config.headers["Authorization"] = `Bearer ${user.token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (config) => {
    return config;
  },
  async (error) => {
    //const ogConfig = error.config;
    if (error.response) {
      let user = store.getState().auth;
        switch (error.response.status) {
        case 400:
        case 401:
        case 403:
          if (user.loading) return axiosInstance.request(error.config);
          else if (user.email == '') window.location.href = '/account/login';
          else window.location.href = '/access-denied';
          break;
        default:
          break;
      }
      //Moved to check token on request, leaving this here in case new method doesn't work out
      //if (error.response.status === 401 && !ogConfig._retry && store.getState().auth.refreshToken != null) {
      //  ogConfig._retry = true;
      //  try {
      //    store.dispatch(getNewToken({ refreshtoken: store.getState().auth.refreshToken }));
      //  } catch (_error) {
      //    return Promise.reject(_error);
      //  }
    }
    return Promise.reject(error);
  }
)

export default axiosInstance;