import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector } from 'react-redux';
import PropTypes from "prop-types";
import * as knowledge from "../../api/knowledgecenterApi";
import * as pressReleases from "../../api/pressreleasesApi";
import * as pages from "../../api/pagesApi";

export default function Drawer({ isEUI }) {
    const navigate = useNavigate();
    const { isAuthenticated } = useSelector((state) => state.auth);
    const [expanded, setExpanded] = useState(false);
    const [urlPath, setUrlPath] = useState('');
    const [editPath, setEditPath] = useState('');
    const [isEdit, setIsEdit] = useState(false);

    if (urlPath != window.location.pathname) {
        if (isAuthenticated) {
            setUrlPath(window.location.pathname);
            var parts = window.location.pathname.split('/').filter(a => a != '' && a != 'can');
            var pathParts;
            if (parts.length > 2 && parts[0] == 'siteadmin' && parts[1] == 'knowledge-center' && parts[2] == 'edit') {
                pathParts = window.location.pathname.split('knowledge-center/edit/')[1].split('/');
                knowledge.getSlug(pathParts[0]).then(results => {
                    setEditPath((results.data.data.region != '' ? '/' + results.data.data.region : '') + '/knowledge-center/' + results.data.data.slug);
                    setIsEdit(true);
                });
            } else if (parts.length > 1 && parts[0] == 'knowledge-center' && parts[1] != '' && parts[1] != 'category' && parseInt(parts[1]) != 'NaN') {
                knowledge.getBySlug(window.location.pathname.split('knowledge-center/')[1]).then(results => {
                    try {
                        setEditPath('/siteadmin/knowledge-center/edit/' + results.data.data.baseId);
                        setIsEdit(false);
                    } catch (e) { }
                });
            } else if (parts.length > 2 && parts[0] == 'siteadmin' && parts[1] == 'press-releases' && parts[2] == 'edit') {
                pathParts = window.location.pathname.split('press-releases/edit/')[1].split('/');
                pressReleases.getSlug(pathParts[0]).then(results => {
                    setEditPath('/press-releases/' + results.data.data);
                    setIsEdit(true);
                });
            } else if (parts.length > 1 && parts[0] == 'press-releases' && parts[1] != '' && parts[1] != 'category' && parseInt(parts[1]) != 'NaN') {
                pressReleases.getBySlug(window.location.pathname.split('press-releases/')[1]).then(results => {
                    try {
                        setEditPath('/siteadmin/press-releases/edit/' + results.data.data.baseId);
                        setIsEdit(false);
                    } catch (e) { }
                });
            } else if (parts.length > 2 && parts[0] == 'siteadmin' && parts[1] == 'pages' && parts[2] == 'edit') {
                pathParts = window.location.pathname.split('pages/edit/')[1].split('/');
                pages.getBySlugAdmin(pathParts[0]).then(results => {
                    setEditPath('/' + results.data.data);
                    setIsEdit(true);
                });
            } else {
                pages.getBySlugAdmin(window.location.pathname).then(results => {
                    if (results.data.success) {
                        setEditPath('/siteadmin/pages/edit/' + results.data.data.baseId);
                        setIsEdit(true);
                    }
                });
                setEditPath('');
            }
        } else if (window.location.pathname.indexOf('/siteadmin/') >= 0) {
            navigate('/account/login');
        }
    }


    const displayName = 'Administrator';
    if (isAuthenticated)
        return (
            <nav className={"admin-drawer-wrap" + (isEUI ? " isEUI" : "") + (expanded === true ? " expanded" : "")}>
                {isEUI &&
                    <div className="admin-drawer-collapsed">
                        {editPath != "" &&
                            <Link className="admin-edit-page-btn" to={editPath}>
                                <span
                                    className="material-symbols-outlined"
                                    aria-hidden="true"
                                >
                                    edit
                                </span>
                            </Link>
                        }
                        <div className="admin-view-menu-btn" onClick={() => setExpanded(!expanded)}>
                            <span
                                className="material-symbols-outlined"
                                aria-hidden="true"
                            >
                                menu
                            </span>
                        </div>
                    </div>
                }
                <div className="admin-drawer-expanded">
                    {isEUI &&
                        <div className="admin-drawer-collapse-btn" onClick={() => setExpanded(!expanded)}>
                            <span
                                className="material-symbols-outlined"
                                aria-hidden="true"
                            >
                                chevron_left
                            </span>
                        </div>
                    }
                    <div className={"admin-drawer-menu"}>
                        {editPath && (
                            <a
                                className="admin-drawer-view-page"
                                href={editPath}
                            >
                                {isEdit ? 'View Page' : 'Edit Page'}
                            </a>
                        )}
                        <div className="admin-drawer-info-wrap">
                            <p className="admin-drawer-info-title"><Link to="/">Koch Agronomic Services</Link></p>
                            <p className="admin-drawer-info-user">{displayName}</p>
                        </div>
                        <div className="admin-drawer-link-wrap">

                            <a className="admin-drawer-link" href="/siteadmin/products">
                                <span
                                    className="admin-drawer-link-icon material-symbols-outlined"
                                    aria-hidden="true"
                                >
                                    flowsheet
                                </span>
                                <span className="admin-drawer-link-text">
                                    Products
                                </span>
                            </a>

                            <a className="admin-drawer-link" href="/siteadmin/knowledge-center">
                                <span
                                    className="admin-drawer-link-icon material-symbols-outlined"
                                    aria-hidden="true"
                                >
                                    lightbulb
                                </span>
                                <span className="admin-drawer-link-text">Knowledge Center</span>
                            </a>

                            <a className="admin-drawer-link" href="/siteadmin/product-labels">
                                <span
                                    className="admin-drawer-link-icon material-symbols-outlined"
                                    aria-hidden="true"
                                >
                                    folder_copy
                                </span>
                                <span className="admin-drawer-link-text">
                                    Product Labels/SDSs
                                </span>
                            </a>

                            <a className="admin-drawer-link" href="/siteadmin/reps-retailers">
                                <span
                                    className="admin-drawer-link-icon material-symbols-outlined"
                                    aria-hidden="true"
                                >
                                    contacts
                                </span>
                                <span className="admin-drawer-link-text">Reps & Retailers</span>
                            </a>

                            <a className="admin-drawer-link" href="/siteadmin/press-releases">
                                <span
                                    className="admin-drawer-link-icon material-symbols-outlined"
                                    aria-hidden="true"
                                >
                                    campaign
                                </span>
                                <span className="admin-drawer-link-text">Press Releases</span>
                            </a>

                            <a className="admin-drawer-link" href="/siteadmin/pages">
                                <span
                                    className="admin-drawer-link-icon material-symbols-outlined"
                                    aria-hidden="true"
                                >
                                    note_stack
                                </span>
                                <span className="admin-drawer-link-text">Pages</span>
                            </a>

                            <a className="admin-drawer-link" href="/siteadmin/document-library">
                                <span
                                    className="admin-drawer-link-icon material-symbols-outlined"
                                    aria-hidden="true"
                                >
                                    folder_open
                                </span>
                                <span className="admin-drawer-link-text">Document Library</span>
                            </a>

                            <a className="admin-drawer-link" href="/siteadmin/forms">
                                <span
                                    className="admin-drawer-link-icon material-symbols-outlined"
                                    aria-hidden="true"
                                >
                                    note_stack
                                </span>
                                <span className="admin-drawer-link-text">Forms</span>
                            </a>

                            <a className="admin-drawer-link" href="/siteadmin/users">
                                <span
                                    className="admin-drawer-link-icon material-symbols-outlined"
                                    aria-hidden="true"
                                >
                                    group
                                </span>
                                <span className="admin-drawer-link-text">Users</span>
                            </a>

                            <a className="admin-drawer-link" href="/siteadmin/redirects">
                                <span
                                    className="admin-drawer-link-icon material-symbols-outlined"
                                    aria-hidden="true"
                                >
                                    arrow_split
                                </span>
                                <span className="admin-drawer-link-text">Redirects</span>
                            </a>

                            <a className="admin-drawer-link" href="/account/logout">
                                <span
                                    className="admin-drawer-link-icon material-symbols-outlined"
                                    aria-hidden="true"
                                >
                                    logout
                                </span>
                                <span className="admin-drawer-link-text">Logout</span>
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        );
    else return null;
}

Drawer.propTypes = {
    isEUI: PropTypes.bool,
};
