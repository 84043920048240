import React from 'react';
import { Link } from 'react-router-dom';
import './MegaMenu.css';
import WolfTrax from '../../assets/WOLF-TRAX-TM.png';
import Agrotain from '../../assets/AGROTAIN-TM.png';
import Anvol from '../../assets/ANVOL-TM.png';
import Centuro from '../../assets/CENTURO-TM.png';
import Superu from '../../assets/SUPERU-TM.png';
import Tribune from '../../assets/TRIBUNE-TM.png';
import RocketSeeds from '../../assets/RocketSeeds-Logo.png';
import Nitamin from '../../assets/nitamin-logo.svg';
import Optrient from "../../assets/OPTRIENT-TM.png";
import Synthos from '../../assets/SYNTHOS-TM.png';
import Protivate from "../../assets/rocket-seeds-protivate-now-popup.png";


export const MegaMenuCa = ({ClickProduct}) => {

    return (
        <section className={'mega-menu-wrapper'}>
            <div className={'mega-menu-container mega-menuCa-container'}>
          
                    <div className={'menu-row'}>
                        <div className={'column-4'}>
                            <p className={'menu-card-title'}>Nutrient Management</p>
                            {/* <Link to={"/nitamin-ca"} className={'menu-card'} onClick={ClickProduct}>
                                <img src={Nitamin} alt="logo" className={'product-logo'} />
                                <p className="menu-card-content">
                                   NITAMIN&trade; fertilizer gives crops targeted foliar nutrition when 
                                   it's needed.
                                </p>
                                <div className={'card-arrow-block orange'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#CE470D" className="bi bi-arrow-right-short" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" /> </svg>
                                </div>
                            </Link> */}
                            <Link
                to={"/nitamin-ca"}
                className={"menu-card"}
                onClick={ClickProduct}
              >
                <img src={Optrient} alt="logo" className={"product-logo"} />
                <p className="menu-card-content">
                  OPTRIENT&trade; foliar nutrients can address deficiencies and
                  maximize performance by applying targeted nutrition.
                </p>
                <div className={"card-arrow-block orange"}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#CE470D"
                    className="bi bi-arrow-right-short"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path
                      fill-rule="evenodd"
                      d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"
                    />{" "}
                  </svg>
                </div>
              </Link>
                            <Link to={"/synthos-ca"} className={'menu-card'} onClick={ClickProduct}>
                                <img src={Synthos} alt="logo" className={'product-logo'} />
                                <p className="menu-card-content">
                                    SYNTHOS&trade; is designed to optimize crop growth by producing enzymes and organic acids
                                    that improve solubilization.
                                </p>
                                <div className={'card-arrow-block purple-icon'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#CE470D" class="bi bi-arrow-right-short" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" /> </svg>
                                </div>
                            </Link>
                            <Link to={"/wolf-trax-ca"} className={'menu-card'} onClick={ClickProduct}>
                                <img src={WolfTrax} alt="logo" className={'product-logo'} />
                                <p className="menu-card-content">
                                   WOLF TRAX&trade; DDP&trade; micronutrients are field-proven to boost crop performance.
                                </p>
                                <div className={'card-arrow-block gray'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#CE470D" className="bi bi-arrow-right-short" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" /> </svg>
                                </div>
                            </Link>
                        </div>

                        <div className={'column-4 nutrient-column'}>
                            <p className={'menu-card-title'}>Nutrient Protection</p>
                            <Link to={"/agrotain-ca"} className={'menu-card'} onClick={ClickProduct}>
                                <img src={Agrotain} alt="logo" className={'product-logo'} />
                                <p className="menu-card-content">
                                    <b className={'d-block'}>Above Ground</b>
                                AGROTAIN&trade; nitrogen stabilizer is the most research-proven urease inhibitor on the market. 
                            </p>
                                <div className={'card-arrow-block green'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#CE470D" className="bi bi-arrow-right-short" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" /> </svg>
                                </div>
                            </Link>
                            <Link to={"/anvol-ca"} className={'menu-card'} onClick={ClickProduct}>
                                <img src={Anvol} alt="logo" className={'product-logo'} />
                                <p className="menu-card-content">
                                    <b className={'d-block'}>Above Ground</b>
                                    ANVOL&trade; nitrogen stabilizer will protect against volatilization and provide superior flexibility.
                                </p>
                                <div className={'card-arrow-block green'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#CE470D" className="bi bi-arrow-right-short" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" /> </svg>
                                </div>
                            </Link>
                            <Link to={"/centuro-ca"} className={'menu-card'} onClick={ClickProduct}>
                                <img src={Centuro} alt="logo" className={'product-logo'} />
                                <p className="menu-card-content">
                                    <b className={'d-block'}>Below Ground</b>
                                CENTURO&trade; nitrogen stabilizer offers highly-effective below-ground protection against leaching and denitrification.
                                </p>
                                <div className={'card-arrow-block purple'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#CE470D" className="bi bi-arrow-right-short" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" /> </svg>
                                </div>
                            </Link>
                            <Link to={"/superu-ca"} className={'menu-card'} onClick={ClickProduct}>
                                <img src={Superu} alt="logo" className={'product-logo'} />
                                <p className="menu-card-content">
                                    <b className={'d-block'}>Above & Below Ground</b>
                                SUPERU&trade; premium fertilizer provides protection against all three forms of nitrogen loss.
                                </p>
                                <div className={'card-arrow-block blue'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#CE470D" className="bi bi-arrow-right-short" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" /> </svg>
                                </div>
                            </Link>

                            <Link to={"/tribune-ca"} className={'menu-card'} onClick={ClickProduct}>
                                <img src={Tribune} alt="logo" className={'product-logo'} />
                                <p className="menu-card-content">
                                    <b className={'d-block'}>Above & Below Ground</b>
                                    TRIBUNE&trade; nitrogen stabilizer contains both an urease and nitrification inhibitor.
                                </p>
                                <div className={'card-arrow-block sky-blue'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#1B6BB3" class="bi bi-arrow-right-short" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" /> </svg>
                                </div>
                            </Link>

                        </div>

                        <div className={'column-4'}>
                            <p className={'menu-card-title'}>Seed Enhancement</p>
                            <Link to={"/protivate-ca"} className={'menu-card'} onClick={ClickProduct}>
                            <img src={Protivate} alt="logo" className={'product-logo'} />
                                <p className="menu-card-content">
                                    PROTIVATE&trade; delivers a flexible, highly-concentrated nutrient source for young 
                                   crops, while reducing cold and water stress.
                                </p>
                                <div className={'card-arrow-block yellow'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#CE470D" class="bi bi-arrow-right-short" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z" /> </svg>
                                </div>
                            </Link>
                        </div>
                    </div>
                
            </div>
            
        </section>
     
  );
};