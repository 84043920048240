import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from "@reduxjs/toolkit";
import { userLogin } from '../../store/auth/actions';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';

function FormValidationSummary({ display, messageList }) {
  if (display) {
    const listElements = messageList.map(message =>
      <li key={message}>{message}</li>
    );
    return (
      <div className="login-form-cell">
        <div className="validation-summary-valid" data-valmsg-summary="true">
          <ul>
            {listElements}
          </ul>
        </div>
      </div>
    );
  }
  else return null;
}

FormValidationSummary.propTypes = {
  display: PropTypes.bool,
  messageList: PropTypes.array
}

export default function Login() {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [displayError, setDisplayError] = useState(false);
  const [messages, setMessages] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const login = bindActionCreators(userLogin, dispatch);


  const handleSubmit = async (event) => {
    event.preventDefault();
    setMessages([]);
    setDisplayError(false);

    let hasError = false;
    if (document.querySelector('.input-validation-error')) {
      document.querySelectorAll('.input-validation-error').forEach((element) => {
        element.classList.remove('input-validation-error');
      })
    }

    if (username === '' || username === null) {
      hasError = true;
      document.getElementById('login-email').classList.add('input-validation-error');
      setMessages(messages => [...messages, 'Please enter your username.']);
    }
    if (password === '' || password === null) {
      setMessages(messages => [...messages, 'Please enter your password.']);
      document.getElementById('login-password').classList.add('input-validation-error');
      hasError = true;
    }
    if (hasError) {
      setDisplayError(true);
      return;
    }

    let data = {
      "Username": username,
      "Password": password
    }

    setIsProcessing(true);
    const result = await login({ data: data }).unwrap();

    if (result.data.success) {
      setIsProcessing(false)
      navigate("/");
    } else {
      setMessages(messages => [...messages, result.data.message]);
      setDisplayError(true);
      setIsProcessing(false);
    }
  }

  useEffect(() => {
    document.querySelectorAll('.fancy-field').forEach((element) => {
      if (element.value != '') {
        element.parentNode.classList.add('input-focused');
      }
      else if (element.parentNode.classList.contains('input-focused')) {
        element.parentNode.classList.remove('input-focused');
      }
    });
  }, [username, password]);

  return (<>
    <p className="adminAccountPageTitle">Site Administration Login</p>
    <div className="medSpace">&nbsp;</div>
    <div className="form">
      <form onSubmit={handleSubmit}>
        <FormValidationSummary
          display={displayError}
          messageList={messages} />
        <div className="adminAccountInput adminAccountInputIcon aaiUser">
              <input className="login-input fancy-field"
                id="login-email"
            name="Username"
                placeholder="email address"
                onChange={(e) => setUserName(e.target.value)}
                value={username}
                type="text" />
        </div>
        <div className="adminAccountInput adminAccountInputIcon aaiPwd">
              <input className="login-input fancy-field"
                id="login-password"
                placeholder="password"
                name="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type="password" />
        </div>
        <div className="login-form-row forgot-cell">
          <div className="login-form-cell">
            <a href="/account/forgot-password" className="forgotPassword" onClick={() => navigate('/account/forgot-password')}>Forgot Password?</a>
          </div>
        </div>
        <div className="login-form-row">
          <div className="login-form-cell">
            {isProcessing ?
              <button type="button" className="login-submit adminAccountSubmit" onClick={() => { }}>Logging in...</button> :
              <button type="submit" className="login-submit adminAccountSubmit">Login</button>
            }
          </div>
        </div>
      </form>
    </div></>
  )
}