import React from 'react';
import { useNavigate } from 'react-router-dom';
import './StepForm.css';
import WolfTrax from '../../assets/product-labels/WolfTrax-Logo.svg'
import Protivate from '../../assets/product-labels/protivate.png'
import Anvol from '../../assets/product-labels/anvol.png'
import Centuro from '../../assets/product-labels/centuro.png'
import Superu from '../../assets/product-labels/superu.png'
import { Button } from '../Button/Button.jsx';
import StepFormClose from '../../assets/step-form-close.svg';

export const StepSeven = ({ startOver, close, step }) => {
    const navigate = useNavigate();
    const selections = [
        [ //step #1: What challenges are you facing?
            { name: 'Early Crop Growth Emergence' },
            { name: 'Early Season Nutrition' },
            { name: 'Micronutrient Deficiencies' },
            { name: 'Micronutrient Use Efficiency' },
            { name: 'Nitrogen Loss Protection' },
            { name: 'Nitrogen Use Efficiency' },
            { name: 'Planter Flowability' },
            { name: 'Reducing Early-Season Stress' }
        ],
        [ //step #2: How would you like to apply your nutrients?
            { name: 'Granular Fertilizers' },
            { name: 'Seed Enhancer' },
        ],
        [ //step #3: What crop are you growing?
            { name: 'Corn' },
            { name: 'Soybean' },
            { name: 'Peas' },
            { name: 'Lentils' },
            { name: 'Peanuts' },
            { name: 'Alfalfa' },
            { name: 'Wheat' },
            { name: 'Cotton' },
            { name: 'Sunflowers' },
            { name: 'Canola' },
            { name: 'Rice' },
            { name: 'Other' },
        ],
        [ //step #4: What are you deficient in?
            { name: 'Zinc' },
            { name: 'Boron' },
            { name: 'Copper' },
            { name: 'Iron' },
            { name: 'Manganese' },
            { name: 'Magnesium' },
            { name: 'Calcium' },
            { name: 'Blend of Nutrients' }
        ],
        [ //step #5: Will you be treating on-farm/at the planter?
            { name: 'Yes' },
            { name: 'No' }
        ],
        [ //step #6: What nitrogen source are you applying?
            { name: 'Urea' },
            { name: 'UAN' },
            { name: 'Anhydrous Ammonia' }
        ],

    ];
    const info = [];
    for (var x = 0; x < 6; x++) {
        if (x == 3) { //deficient nutrients
            if (step.steps[x + 1] != null) { info.push({ name: step.steps[x + 1].map(a => selections[x][a - 1].name).join(', ') }); }
        } else {//everything else
            if (step.steps[x + 1] != null) { info.push(selections[x][step.steps[x + 1] - 1]); }
        }
    }
    const deficiencies = step.steps[4] ? step.steps[4] : [];
    const deficientInfo = [
        {
            title: <>Zinc DDP&trade;</>,
            summary: <>
                Coat every granule in a fertilizer blend with Zinc DDP&trade; for an even distribution across the field and better nutrient availability
                throughout a crop's growth cycle. Its guaranteed analysis is 62% zinc derived from two forms: zinc oxide and zinc sulfate.</>,
            detailsUrl: '/wolf-trax',
            productPdf: '/doccenter/5cb4eb47-59c5-4c89-8fee-2203d786924c',
            sdsPdf: '/doccenter/b0f5f14c-dc1e-46af-97df-6f11e201e84d'
        },
        {
            title: <>Boron DDP&trade;</>,
            summary: <>
                Coat every granule in a fertilizer blend with Boron DDP&trade; for an even distribution across the field and better nutrient availability
                throughout a crop's growth cycle. Its guaranteed analysis is 18% boron derived from three forms: boric acid, sodium tetraborate and potassium tetraborate.</>,
            detailsUrl: '/wolf-trax',
            productPdf: '/doccenter/f4b02690-16c5-47b9-badc-183872733d3b',
            sdsPdf: '/doccenter/33186719-3877-456a-8f16-d217435039d9'
        },
        {
            title: <>Copper DDP&trade;</>,
            summary: <>
                Coat every granule in a fertilizer blend with Copper DDP&trade; for an even distribution across the field and better nutrient availability
                throughout a crop's growth cycle. Its guaranteed analysis is 56.5% copper derived from two forms: copper oxide and copper sulfate.</>,
            detailsUrl: '/wolf-trax',
            productPdf: '/doccenter/07b214c9-88a7-4469-a0eb-2c063165a33e',
            sdsPdf: '/doccenter/06b6fb00-33d5-4584-8587-f635351c03da'
        },
        {
            title: <>Iron DDP&trade;</>,
            summary: <>
                Coat every granule in a fertilizer blend with Iron DDP&trade; for an even distribution across the field and better nutrient availability
                throughout a crop's growth cycle. Its guaranteed analysis is 46.5% iron derived from two forms: iron oxide and amino acid complexed iron.</>,
            detailsUrl: '/wolf-trax',
            productPdf: '/doccenter/ae7c3bb9-2366-40dc-9efc-0e20dda7a0eb',
            sdsPdf: '/doccenter/7eeb8a5c-23ab-4df5-bb14-9ec5f6099137'
        },
        {
            title: <>Manganese DDP&trade;</>,
            summary: <>
                Coat every granule in a fertilizer blend with Manganese DDP&trade; for an even distribution across the field and better nutrient availability
                throughout a crop's growth cycle. Its guaranteed analysis is 33% manganese derived from three forms: manganese oxide, manganese sulfate and
                manganese chloride.</>,
            detailsUrl: '/wolf-trax',
            productPdf: '/doccenter/9c5fbebc-90df-4805-812e-89a3b8dbc6df',
            sdsPdf: '/doccenter/64fcfecc-202e-4664-8785-1e83b7738d6e'
        },
        {
            title: <>Magnesium DDP&trade;</>,
            summary: <>
                Coat every granule in a fertilizer blend with Magnesium DDP&trade; for an even distribution across the field and better nutrient availability
                throughout a crop's growth cycle. Its guaranteed analysis is 30% magnesium derived from three forms: magnesium oxide, magnesium carbonate and
                magnesium sulfate.</>,
            detailsUrl: '/wolf-trax',
            productPdf: '/doccenter/269c279b-f5d8-44bc-8fe3-84fbc2d478a8',
            sdsPdf: '/doccenter/33a6ffe9-10a7-4964-ac4a-7b00769661df'
        },
        {
            title: <>Calcium DDP&trade;</>,
            summary: <>
                Coat every granule in a fertilizer blend with Calcium DDP&trade; for an even distribution across the field and better nutrient availability
                throughout a crop's growth cycle. Its guaranteed analysis is 27% calcium derived from three forms: calcium nitrate, calcium sulfate and calcium
                carbonate.</>,
            detailsUrl: '/wolf-trax',
            productPdf: '/doccenter/93f94278-c8b7-4745-af5e-f5d50d34d4b5',
            sdsPdf: '/doccenter/d311694a-dc4a-4862-b1fb-fc26b49beb67'
        }
    ]
    return (
        <section className={'stepForm stepForm-six'}>
            <div className="stepOne-formWrapper mx-auto">
                <div className="mega-menu-container">
                    <div className="mega-menu-container-inner">
                <p className="stepForm-question text-center">Your Solutions</p>
                <p className="text-center stepForm-info">You selected: {info.map(a => a.name).join(' > ')}</p>
                <div className="solution-card-row">
                    {step.steps[2] == 1 && deficiencies.map(a => { //Deficient Nutrients //////////////////////////////////////////////////////////////////////////////////////////
                        if (a == 8) { return (<></>); }
                        const deficient = deficientInfo[a - 1];
                        return (<div className="solution-card" key={'deficient_' + a}>
                            <img src={WolfTrax} alt="wolf trax logo" />
                            <div className="stepForm-solution-content wolftrax-us">
                                <p className="solution-content-heading m-0">{deficient.title}</p>
                                <p className="solution-content-subHeading">
                                    {deficient.summary}
                                </p>
                                <div className="wolftrax-us btn-wrapper">
                                    <a className="button default-button default-button--secondary" href={deficient.productPdf} target="_blank" rel="noreferrer">Product Label</a>
                                    <a className="button default-button default-button--secondary true" href={deficient.sdsPdf} target="_blank" rel="noreferrer">SDS</a>
                                </div>
                                <Button label="Product Details" primary="true" onClick={() => { navigate(deficient.detailsUrl) }} />
                            </div>
                        </div>);
                    })}
                    {step.steps[2] == 1 && //Granular Fertilizers //////////////////////////////////////////////////////////////////////////////////////////
                        <>
                            <div className="solution-card">
                                <img src={WolfTrax} alt="wolf trax logo" />
                                <div className="stepForm-solution-content wolftrax-us">
                                    <p className="solution-content-heading m-0">CROPMIX&trade; </p>
                                    <p className="solution-content-subHeading">
                                        Coat every granule in a fertilizer blend with CROPMIX&trade; for an even distribution across the field and better nutrient availability
                                        throughout a crop's growth cycle. Its guaranteed analysis is derived from 17% zinc, 2% boron, 18% manganese, 1% copper and 1% iron.
                                    </p>
                                    <div className="wolftrax-us btn-wrapper">
                                        <a className="button default-button default-button--secondary" href="/doccenter/f8e57f40-72f6-4b6c-b119-25dfc676b167" target="_blank" rel="noreferrer">Product Label</a>
                                        <a className="button default-button default-button--secondary true" href="/doccenter/2765f5d5-891f-4452-b71d-a092438f00b0" target="_blank" rel="noreferrer">SDS</a>
                                    </div>
                                    <Button label="Product Details" primary="true" onClick={() => { navigate('/wolf-trax') }} />
                                </div>
                            </div>
                                {(step.steps[1] == 1 || step.steps[1] == 8) &&
                                <div className="solution-card">
                                    <img src={WolfTrax} alt="wolf trax logo" />
                                    <div className="stepForm-solution-content wolftrax-us">
                                        <p className="solution-content-heading m-0">NU-TRAX P+&trade;</p>
                                        <p className="solution-content-subHeading">
                                            Coat every granule in a fertilizer blend with NU-TRAX P+&trade; for an even distribution across the field and better nutrient availability
                                            throughout a crop's growth cycle. Its guaranteed analysis is derived from 20% zinc, 25% available phosphate, 5% manganese and 4% nitrogen.
                                        </p>
                                        <div className="wolftrax-us btn-wrapper">
                                            <a className="button default-button default-button--secondary" href="/doccenter/cfc94e65-715f-4bf4-b6ac-0c9f528622b0" target="_blank" rel="noreferrer">Product Label</a>
                                                <a className="button default-button default-button--secondary true" href="/doccenter/5b1714e6-403e-4643-a366-86089dd2d389" target="_blank" rel="noreferrer">SDS</a>
                                        </div>
                                        <Button label="Product Details" primary="true" onClick={() => { navigate('/wolf-trax') }} />
                                    </div>
                                </div>
                            }

                            <div className="solution-card">
                                <img src={WolfTrax} alt="wolf trax logo" />
                                <div className="stepForm-solution-content wolftrax-us">
                                    <p className="solution-content-heading m-0">3-TRAX&trade; </p>
                                    <p className="solution-content-subHeading">
                                        Coat every granule in a fertilizer blend with 3-TRAX&trade; for an even distribution across the field and better nutrient availability throughout
                                        a crop's growth cycle. Its guaranteed analysis is derived from 26% zinc, 3% boron and 13% manganese.
                                    </p>
                                    <div className="wolftrax-us btn-wrapper">
                                        <a className="button default-button default-button--secondary" href="/doccenter/6e6a52e0-ec3b-4de9-af30-a0249c9876c9" target="_blank" rel="noreferrer">Product Label</a>
                                            <a className="button default-button default-button--secondary true" href="/doccenter/0607b5d2-f869-434b-a265-de5e2d0d1cbd" target="_blank" rel="noreferrer">SDS</a>
                                    </div>
                                    <Button label="Product Details" primary="true" onClick={() => { navigate('/wolf-trax') }} />
                                </div>
                            </div>
                        </>
                    }
                    {step.steps[2] == 2 && step.steps[5] == 1 && //Seed Enhancer (On-Farm) //////////////////////////////////////////////////////////////////////////////////////////
                        <>
                            <div className="solution-card">
                                <img src={Protivate} alt="protivate logo" />
                                <div className="stepForm-solution-content protivate">
                                    <p className="solution-content-heading m-0">NU4-DRI</p>
                                    <p className="solution-content-subHeading">
                                        NU4-DRI is ideally formulated for corn, wheat and other major row crops. It contains nitrogen, a critical component of
                                        proteins and is needed for the chlorophyll molecule to enable photosynthesis.
                                    </p>
                                    <div className="red btn-wrapper">
                                            <a className="button default-button default-button--secondary" href="/doccenter/866af2b2-f632-4470-bd70-17b8c5c9d01b" target="_blank" rel="noreferrer">Product Label</a>
                                        <a className="button default-button default-button--secondary true" href="/doccenter/516063c1-61db-4580-8a58-be168fccf3f2" target="_blank" rel="noreferrer">SDS</a>
                                    </div>
                                    <Button label="Product Details" primary="true" onClick={() => { navigate('/protivate') }} />
                                </div>
                            </div>
                            <div className="solution-card">
                                <img src={Protivate} alt="protivate logo" />
                                <div className="stepForm-solution-content protivate">
                                    <p className="solution-content-heading m-0">NU5-DRI</p>
                                    <p className="solution-content-subHeading">
                                        NU5-DRI is ideally formulated for soybean, legume and other pulse crops. 
                                        It contains molybdenum which can contribute to improved metabolic 
                                        processes, support proper nodulation development and efficient nitrogen 
                                        fixation.
                                    </p>
                                    <div className="red btn-wrapper">
                                        <a className="button default-button default-button--secondary" href="/doccenter/fec46d0f-6830-4e2e-b2f5-0ada2ebe1cd6" target="_blank" rel="noreferrer">Product Label</a>
                                            <a className="button default-button default-button--secondary true" href="/doccenter/6c0bfd0b-5124-4fdb-8a8a-005254f37c1e" target="_blank" rel="noreferrer">SDS</a>
                                    </div>
                                    <Button label="Product Details" primary="true" onClick={() => { navigate('/protivate') }} />
                                </div>
                            </div>
                        </>
                    }
                    {step.steps[2] == 2 && step.steps[5] == 2 && //Seed Enhancer (NOT On-Farm) //////////////////////////////////////////////////////////////////////////////////////////
                        <>
                            <div className="solution-card">
                                <img src={Protivate} alt="protivate logo" />
                                <div className="stepForm-solution-content protivate">
                                    <p className="solution-content-heading m-0">NU5-LUX</p>
                                    <p className="solution-content-subHeading">
                                        NU5-LUX has the same nutritional package as NU5-DRI. It contains iron 
                                        and molybdenum and is ideally formulated for soybean, legume and other 
                                        pulse crops. The difference is an added shine component to enhance the 
                                        seed's finished appearance.
                                    </p>
                                    <div className="red btn-wrapper">
                                        <a className="button default-button default-button--secondary" href="/doccenter/5ed64c4b-f8d3-4ec3-afef-41c6caf86889" target="_blank" rel="noreferrer">Product Label</a>
                                            <a className="button default-button default-button--secondary true" href="/doccenter/049a3912-39ad-4fea-be74-5bcfef28f903" target="_blank" rel="noreferrer">SDS</a>
                                    </div>
                                    <Button label="Product Details" primary="true" onClick={() => { navigate('/protivate') }} />
                                </div>
                            </div>
                        </>
                    }
                    {(step.steps[6] == 1 || step.steps[6] == 2) && //Nitrogen Products //////////////////////////////////////////////////////////////////////////////////////////
                        <>
                            <div className="solution-card">
                                <img src={Anvol} alt="anvol logo" />
                                <div className="stepForm-solution-content anvol-us green">
                                    <p className="solution-content-heading m-0">ANVOL&trade;</p>
                                    <p className="solution-content-subHeading">
                                        Reap the rewards of long-lasting nitrogen protection with ANVOL&trade; nitrogen stabilizer. Powered by dual-active
                                        ingredients NBPT and Duromide, it's designed to defend against volatilization while boosting overall efficiency.
                                    </p>
                                    <div className="green btn-wrapper">
                                        <a className="button default-button default-button--secondary" href="/doccenter/48f88ead-0bd9-4e3c-ac8d-14acfe754488" target="_blank" rel="noreferrer">Product Label</a>
                                        <a className="button default-button default-button--secondary true" href="/doccenter/8ff91510-7abe-4b36-873e-ac9c793633fe" target="_blank" rel="noreferrer">SDS</a>
                                    </div>
                                    <Button label="Product Details" primary="true" onClick={() => { navigate('/anvol') }} />
                                </div>
                            </div>
                        </>
                    }
                    {(step.steps[6] == 1) &&
                        <>
                            <div className="solution-card">
                                <img src={Superu} alt="superu logo" />
                                <div className="stepForm-solution-content superu-us">
                                    <p className="solution-content-heading m-0">SUPERU&trade;</p>
                                    <p className="solution-content-subHeading">
                                        Reap the rewards of longer lasting nitrogen protection with ANVOL&trade; nitrogen stabilizer. Powered by dual-active
                                        ingredients NBPT and Duromide, it's designed to defend against volatilization while boosting overall efficiency.
                                    </p>
                                    <div className="blue btn-wrapper">
                                        <a className="button default-button default-button--secondary" href="/doccenter/8963f9d0-111c-40f9-813c-16e88322a006" target="_blank" rel="noreferrer">Product Label</a>
                                            <a className="button default-button default-button--secondary true" href="/doccenter/1b7cb37d-7e9f-4020-8465-76debac72658" target="_blank" rel="noreferrer">SDS</a>
                                        </div>
                                    <div className="blue">
                                         <Button label="Product Details" primary="true" onClick={() => { navigate('/superu') }} />
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                    {(step.steps[6] == 2 || step.steps[6] == 3) &&
                        <>
                            <div className="solution-card">
                                <img src={Centuro} alt="centuro logo" />
                                <div className="stepForm-solution-content centuro-us">
                                    <p className="solution-content-heading m-0">CENTURO&trade;</p>
                                    <p className="solution-content-subHeading">
                                        Optimize yield results with the proven efficiency and unrivaled protection of CENTURO&trade; nitrogen stabilizer.
                                        Featuring the patented active ingredient Pronitridine, it's an easy-to-handle solution offering highly effective below-ground nitrogen defense.
                                    </p>
                                    <div className="purple btn-wrapper">
                                        <a className="button default-button default-button--secondary" href="/doccenter/a18ddf02-4ec6-42bc-8cbe-dec1e451ef6f" target="_blank" rel="noreferrer">Product Label</a>
                                        <a className="button default-button default-button--secondary true" href="/doccenter/d125a401-a23a-4f38-8ea0-cd2ab48b01e7" target="_blank" rel="noreferrer">SDS</a>
                                    </div>
                                    <div className="purple">
                                        <Button label="Product Details" primary="true" onClick={() => { navigate('/centuro') }} />
                                    </div>
                                </div>
                            </div>
                        </>
                    }

                </div>
                    </div>
                </div>
            </div>
            <div className="stepForm-btnWrapper">
                <div className="stepForm-btn-flex">
                    <Button onClick={startOver} className="default-btn back-default-btn start-over-btn"
                        label="Start Over" />
                    <Button onClick={() => { navigate('/find-a-rep'); }} className="default-btn"
                        label="Find a Retailer/Rep" />
                </div>

            </div>
        </section>
    );
};