import api from '../utils/adminApi';

//public APIs
export const details = (id) => {
    return api.get(`/api/knowledge-center/details/${id}`);
}

export const filter = (filterParams) => {
    return api.post(`/api/knowledge-center/filter`, filterParams);
}

export const getBySlug = (slug) => {
    return api.get(`/api/knowledge-center/get-by-slug/${slug}`);
}

//admin APIs
export const add = (knowledgeCenter) => {
    return api.post('/api/admin/knowledge-center/add', knowledgeCenter);
}

export const getByBaseId = (baseid) => {
    return api.get(`/api/admin/knowledge-center/get/${baseid}`);
}

export const remove = (id) => {
    return api.get('/api/admin/knowledge-center/delete/' + id);
}

export const getSlug = (id) => {
    return api.get(`/api/admin/knowledge-center/get-slug/${id}`);
}

export const getVersions = (id) => {
    return api.get(`/api/admin/knowledge-center/get-versions/${id}`);
}

export const pin = (baseId, type, pinned) => {
    return api.post('/api/admin/knowledge-center/pin', { baseId: baseId, type: type, pinned: pinned });
}