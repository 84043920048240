import api from '../utils/publicApi';

export const filter = (filterParams) => {
    return api.post(`/api/pages/filter`, filterParams);
}

export const getBySlug = (slug) => {
  return api.get(`/api/pages/get-by-slug/${slug}`);
}

export const getLatest = (id) => {
    return api.get(`/api/pages/get-latest/` + (id ? id : ''));
}


