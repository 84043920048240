import React, { useEffect, useState, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
//pages
import Page from "../pages/Page";
import AccessDenied from "../pages/Error/AccessDenied";
import NotFound from "../pages/Error/NotFound";
import ServerError from "../pages/Error/ServerError";
//routers
import { accountRoutes } from "./Accountrouter";
import { appRoutes } from "./Approuter";
import { adminRoutes } from "./Adminrouter";
//api
import { GetRedirects } from "../api/redirectApi";
//components
import SuspenseLoadingSpinner from "../components/SuspenseLoadingSpinner";
//store
import { updateRegion } from "./../store/region";

function Element({ element, layout, adminPage, region }) {
    const dispatch = useDispatch();
    const { isAuthenticated } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    const Component = element;
    const Layout = layout;
    if (region != null) {
        dispatch(updateRegion(region));
    }
    useEffect(() => {
        if (adminPage && !isAuthenticated) {
            navigate("/account/login");
        }
    }, []);

    if (adminPage && !isAuthenticated) {
        return null;
    } else {
        return (
            <Layout>
                <Component />
            </Layout>
        );
    }
}

Element.propTypes = {
    Component: PropTypes.any,
    Layout: PropTypes.any,
    //secure: PropTypes.bool,
    //adminPage: PropTypes.bool
};

Element.defaultProps = {
    //secure: true,
    //adminPage: false
};

export const createRoutes = (routesData) =>
    routesData.map((route) => {
        if (route.routes?.length) {
            const indexRoute = route.routes.find((r) => r.index);
            return (
                <Route
                    key={route.path}
                    path={route.path}
                    element={<Element {...route} />}
                    index={route.index}
                >
                    {!!indexRoute && <Route index element={<Element {...route} />} />}
                    {createRoutes(route.routes)}
                </Route>
            );
        }
        return (
            <Route
                key={route.path}
                path={route.path}
                element={<Element {...route} />}
                index={route.index}
            />
        );
    });

export const createLazyRoutes = (routesData) =>
    routesData.map((route) => {
        return (
            <Route
                key={route.path}
                path={route.path}
                element={
                    <Suspense fallback={<SuspenseLoadingSpinner />}>
                        <Element {...route} />
                    </Suspense>
                }
                index={route.index}
            />
        );
    });
export default function AppRouter() {
    return (
        <Routes>
            {createRoutes(accountRoutes)}
            {createLazyRoutes(appRoutes)}
            {createLazyRoutes(adminRoutes)}
            <Route path="not-found" element={<NotFound />} />
            <Route path="access-denied" element={<AccessDenied />} />
            <Route path="error" element={<NotFound />} />
            <Route path="error/server-error" element={<ServerError />} />
            <Route path="careers" element={<Careers></Careers>} />
            <Route path="can/find-a-rep" element={<CanadaFindRep></CanadaFindRep>} />
            <Route path="*" element={<RedirectRoute />} />
        </Routes>
    );
}

function RedirectRoute() {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);

    const getRedirect = async (slug) => {
        const response = await GetRedirects(slug);
        if (response.data.success) {
            window.location.href = response.data.data;
        } else setIsLoading(false);
    };

    useEffect(() => {
        getRedirect(location.pathname.substring(1)).catch(() => <NotFound />);
    }, []);

    if (isLoading) { return null; }
    return (<Page></Page>);
}

function Careers() {
    window.location = 'https://jobs.kochcareers.com/';
}

function CanadaFindRep() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    dispatch(updateRegion(3));
    useEffect(() => {
        navigate('/find-a-rep-ca');
    }, []);
}