import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parseHtml from '../../../utils/parseHtml';
export default function FreeformModule({ module }) {

  const [moduleData, setModuleData] = useState(module);

  useEffect(() => {
    setModuleData(module);
  }, [module]);

  if (moduleData != null && moduleData.content !== null && typeof moduleData.content == 'string')
    return (
      <>
            {parseHtml(moduleData.content)}
      </>
    );
  else return null;
}

FreeformModule.PropType = {
  module: PropTypes.object
}