import React from 'react';
import { Link } from 'react-router-dom';
import './users.css';

export default function Users() {

    const handleAdd = () => {

    };

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link className="admin-top-bar-back" to="/siteadmin/">
                        Back
                    </Link>
                </div>
            </div>
            <div className="admin-page-content">
                <div className="admin-page-title-wrap">
                    <h1 className="admin-page-heading">Users</h1>
                    <button className="admin-button power" onClick={() => handleAdd()}>
                        Add User
                    </button>
                </div>
            </div>
        </div>
  );
}