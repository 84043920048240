import React from 'react';
import PropTypes from 'prop-types';

function AdminLinkTile({ badge, href, icon, name }) {
 const badgeValue = typeof badge === 'number' ? badge : 0;
  return (
    <a className="admin-link-tile" href={href}>
      <span className="admin-link-tile-icon material-symbols-outlined">{icon}</span>
      <span className="admin-link-tile-name">{name}</span>
          {badgeValue > 0 ? <span className="admin-link-tile-badge admin-quotes-box">{badgeValue}</span> : '' }
    </a>
  );
}

AdminLinkTile.propTypes = {
  badge: PropTypes.number,
  href: PropTypes.string,
  icon: PropTypes.string,
  name: PropTypes.string,
}

export default AdminLinkTile;