import React, { useState, useEffect } from "react";
import "./FindRep.css";
import MapBackground from "../../assets/map.png";
import CustomMapBackground from "../../assets/dualpurposemap.png";
import { useNavigate } from "react-router-dom";
import { FindRepForm } from "../../pages/ContactReps";
function FindRep() {
  const navigate = useNavigate();
  const performSearch = (value) => {
    navigate("/reps-retailers", { state: { formData: value } });
    };

    const [showCustomMap, setCustomMap] = useState(false);

    useEffect(() => {
        if (window.location.pathname.indexOf('dual-purpose') >= 0) {
            if (showCustomMap == false) { setCustomMap(true); }
        } else if (showCustomMap == true) {
            setCustomMap(false);
        }
    }, []);
  return (
    <div className="find-rep-wrap" id="find-rep">
      <div className="find-rep-map">
        <div className="find-rep-map-background">
                  <img src={showCustomMap ? CustomMapBackground : MapBackground} alt="Map of the World" />

        </div>
        <div className="find-rep-center">
          <h2 className="find-rep-headline">Find a Rep or Retailer</h2>
          <p className="find-rep-desc">
            With our network of knowledgeable representatives, retailers and
            distributors, you can be confident you will get the best solution
            for your unique needs. Find your nearest Koch Agronomic Services,
            LLC, representative.
          </p>
          <FindRepForm
            performSearch={(value) => {
              performSearch(value);
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default FindRep;
