import React from 'react'
// Layout
import Layout from '../layouts/AdminLayout';

// AUI Pages
import Admin from '../pages/admin/Admin.js';
import { UserIndex, UserEdit } from '../pages/admin/Users';
// import Products from "../pages/admin/Products/products";
// import ProductEdit from '../pages/admin/Products/ProductEdit.js';
// import KnowledgeCenter from "../pages/admin/KnowledgeCenter/knowledgecenter";
// import KnowledgeCenterEdit from "../pages/admin/KnowledgeCenter/knowledgecenteredit";
// import ProductLabels from "../pages/admin/ProductLabels/productlabels";
// import ProductLabelEdit from "../pages/admin/ProductLabels/productlabeledit";
// import RepsRetailers from "../pages/admin/RepsRetailers/repsretailers";
// import PressReleases from "../pages/admin/PressReleases/pressreleases";
// import PressReleasesEdit from "../pages/admin/PressReleases/pressreleasesedit";
// import Pages from "../pages/admin/Pages/pages";
// import PagesEdit from "../pages/admin/Pages/pagesedit";
// import DocumentLibrary from "../pages/admin/DocumentLibrary/documentlibrary";
// import DocumentLibraryEdit from '../pages/admin/DocumentLibrary/documentlibraryedit';
import Forms from "../pages/admin/Forms/forms";
import Users from "../pages/admin/Users/users";
// import Redirects from "../pages/admin/Redirects/redirects";
// import RetailerEdit from '../pages/admin/RepsRetailers/retailerEdit.js';
// import RepEdit from '../pages/admin/RepsRetailers/repEdit.js';
// const Admin = React.lazy(()=>import('../pages/admin/Admin.js'));
const Products = React.lazy(() => import("../pages/admin/Products/products"));
const ProductEdit = React.lazy(() => import('../pages/admin/Products/ProductEdit.js'));
const KnowledgeCenter = React.lazy(() => import("../pages/admin/KnowledgeCenter/knowledgecenter"));
const KnowledgeCenterEdit = React.lazy(() => import("../pages/admin/KnowledgeCenter/knowledgecenteredit"));
const ProductLabels = React.lazy(() => import("../pages/admin/ProductLabels/productlabels"));
const ProductLabelEdit = React.lazy(() => import("../pages/admin/ProductLabels/productlabeledit"));
const RepsRetailers = React.lazy(() => import("../pages/admin/RepsRetailers/repsretailers"));
const PressReleases = React.lazy(() => import("../pages/admin/PressReleases/pressreleases"));
const PressReleasesEdit = React.lazy(() => import("../pages/admin/PressReleases/pressreleasesedit"));
const Pages = React.lazy(() => import("../pages/admin/Pages/pages"));
const PagesEdit = React.lazy(() => import("../pages/admin/Pages/pagesedit"));
const DocumentLibrary = React.lazy(() => import("../pages/admin/DocumentLibrary/documentlibrary"));
const DocumentLibraryEdit = React.lazy(() => import('../pages/admin/DocumentLibrary/documentlibraryedit'));
// const Forms = React.lazy(() => import("../pages/admin/Forms/forms"));
// const Users = React.lazy(() => import("../pages/admin/Users/users"));
const Redirects = React.lazy(() => import("../pages/admin/Redirects/redirects"));
const RetailerEdit = React.lazy(() => import('../pages/admin/RepsRetailers/retailerEdit.js'));
const RepEdit = React.lazy(() => import('../pages/admin/RepsRetailers/repEdit.js'));

export const adminRoutes = [
	{
		path: '/siteadmin',
		element: Admin,
		layout: Layout,
		adminPage: true
	},
	{
		path: '/siteadmin/users',
		element: UserIndex,
		layout: Layout,
		adminPage: true
	},
	{
		path: '/siteadmin/users/add',
		element: UserEdit,
		layout: Layout,
		adminPage: true
	},
	{
		path: '/siteadmin/users/edit/:userId',
		element: UserEdit,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/products',
		element: Products,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/knowledge-center',
		element: KnowledgeCenter,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/knowledge-center/create',
		element: KnowledgeCenterEdit,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/knowledge-center/edit/:baseId/:rollbackId?',
		element: KnowledgeCenterEdit,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/product-labels',
		element: ProductLabels,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/product-labels/create',
		element: ProductLabelEdit,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/product-labels/edit/:baseId/:rollbackId?',
		element: ProductLabelEdit,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/reps-retailers',
		element: RepsRetailers,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/press-releases',
		element: PressReleases,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/press-releases/create',
		element: PressReleasesEdit,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/press-releases/edit/:baseId/:rollbackId?',
		element: PressReleasesEdit,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/pages',
		element: Pages,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/pages/create',
		element: PagesEdit,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/pages/edit/:baseId/:rollbackId?',
		element: PagesEdit,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/document-library',
		element: DocumentLibrary,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/document-library/create',
		element: DocumentLibraryEdit,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/document-library/edit/:baseId',
		element: DocumentLibraryEdit,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/forms',
		element: Forms,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/users',
		element: Users,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/redirects',
		element: Redirects,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/products/edit/:editId',
		element: ProductEdit,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/products/edit/:editId/:rollbackId?',
		element: ProductEdit,
		layout: Layout,
		adminPage: true
	},
	{
		path: 'siteadmin/reps-retailers/retailer/create',
		element: RetailerEdit,
		layout: Layout,
		adminPage: true

	},
	{
		path: 'siteadmin/reps-retailers/rep/create',
		element: RepEdit,
		layout: Layout,
		adminPage: true

	},
	{
		path: 'siteadmin/reps-retailers/rep/edit/:editId',
		element: RepEdit,
		layout: Layout,
		adminPage: true

	},
	{
		path: 'siteadmin/reps-retailers/retailer/edit/:editId/:next?',
		element: RetailerEdit,
		layout: Layout,
		adminPage: true

	},
];
