import React from 'react';
import './Error.css';
import Navigation from '../../components/Navigation/Navigation.js';
import Footer from '../../components/Footer/Footer.js';

export default function AccessDenied() {
  return (
    <div className="full-page-wrap">
      <Navigation />
      <div className="error-page-content">
        <div className="error-page-content-center">
          <h1 className="error-page-heading">Access Denied</h1>
          <p className="error-page-text">Sorry, you don't have permission to access this page.</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}
