import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const location = useLocation();
  let { state } = useLocation();

  useEffect(() => {
    if (!location.pathname.includes("knowledge-center")) {
      window.scrollTo(0, 0);
    }
    // if (state && !state.shouldScrollToTop) {
    //   const filterSection = document.getElementById(
    //     "knowledge-zoomTo-section"
    //   );
    //   if (filterSection) {
    //     filterSection.scrollIntoView(true);
    //   }
    //   else{
    //     window.scrollTo(0,0);
    //   }
    // }
  }, [location]);
  return null;
}
