import React from 'react';
import PropTypes from 'prop-types';
import AdminDrawer from '../components/admin/drawer';
import SessionTimeoutModal from "../components/admin/Editor/SessionTimeoutModal";

export default function AdminLayout({ children }) {
	return (
		<div className="admin-full-wrap">
			<SessionTimeoutModal></SessionTimeoutModal>
			<AdminDrawer />
			{children}
		</div>
	);
}

AdminLayout.propTypes = {
	children: PropTypes.any,
};
