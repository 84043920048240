import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import parseHtml from '../../../utils/parseHtml';
export default function TwoColumnBlockModule({ module }) {

  const [moduleData, setModuleData] = useState(module);

	useEffect(() => {
    setModuleData(module);
  }, [module]);

  if (moduleData !== null && moduleData.content !== null)
    return (
			<div className="knowledge-multi-img-module">
				{/*<p className="knowledge-detail-headline">Multi-image module</p>*/}
				<div className="knowledge-multi-img-wrap">
					{moduleData.content && moduleData.content.map((content, idx) =>
						<div className="knowledge-multi-img-item" key={idx}>
							{content.imageItem &&
								<div className="knowledge-multi-img">
									<img src={content.imageItem.previewsrc} alt={content.imageItem.alttext} />
								</div>}
							<div className="knowledge-multi-img-content">
								{content.headline != '' && <p className="headline">{content.headline}</p>}
								{parseHtml(content.editorData) }
								</div>
						</div>
					)}
					</div>
			</div>
    );
  else return null;
}

TwoColumnBlockModule.PropType = {
  module: PropTypes.object
}