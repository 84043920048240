

export function SetMetaDataDisplay(metaObj) {
	if (metaObj == null) return;
	/* Description */
	let metaDesc = document.head.querySelector('meta[name="description"]');
	if (metaDesc) {
		metaDesc.setAttribute('content', metaObj.desc);
	}
	else document.head.innerHTML += '<meta name="description" content="' + metaObj.desc + '" />';

	/* Og Title */
	let ogTitle = document.head.querySelector('meta[property="og:title"]');
	if (ogTitle) {
		ogTitle.setAttribute('content', (metaObj.ogtitle !== '' ? metaObj.ogtitle : metaObj.title));
	}
	else document.head.innerHTML += '<meta property="og:title" content="' + (metaObj.ogtitle !== '' ? metaObj.ogtitle : metaObj.title) + '" />';

	/* Og Description */
	let ogDesc = document.head.querySelector('meta[property="og:description"]');
	if (ogDesc) {
		ogDesc.setAttribute('content', (metaObj.ogdesc !== '' ? metaObj.ogdesc : metaObj.desc));
	}
	else document.head.innerHTML += '<meta property="og:description" content="' + (metaObj.ogdesc !== '' ? metaObj.ogdesc : metaObj.desc) + '" />';

	/* Og Photo */
	if (metaObj.ogphoto) {
		let ogphoto = document.head.querySelector('meta[property="og:image"]');
		if (ogphoto) {
			ogphoto.setAttribute('content', metaObj.ogphoto.previewsrc);
		}
		else document.head.innerHTML += '<meta property="og:image" content="' + metaObj.ogphoto.previewsrc + '" />';
	}
	let ogType = document.head.querySelector('meta[property="og:type"]');
	if (ogType) {
		ogType.setAttribute('content', (metaObj?.metaContent ?? "website"));
	} else document.head.innerHTML += '<meta property="og:type" content="' + (metaObj?.metaContent ?? "website") + '" />';

}