import adminApi from '../utils/adminApi';
import publicApi from '../utils/publicApi'


// Get product details by ID (public)
export const getProductDetails = (id) => {
    return publicApi.get(`/api/products/details/${id}`);
};

// Get all products (public)
export const getAllProducts = () => {
    return publicApi.post('/api/products/get-all');
};

// Get versions of a product by base ID (admin)
export const getProductVersions = (baseid) => {
    return adminApi.get(`/api/admin/products/get-versions/${baseid}`);
};

// Add a new product (admin)
export const addProduct = (productData) => {
    return adminApi.post('/api/admin/products/add', productData);
};
