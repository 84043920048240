import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import './Page.css';
import './Detail.css';
import './KnowledgeCenter/KnowledgeDetail.css';
//layout
import AppLayout from "../layouts/Layout";
//components
import Modules from "../components/eui/Modules/Modules";
import FullPageAjaxLoader from "../components/FullPageAjaxLoader";
import { LatestResources } from '../components/KnowledgeCenter/latestResources';
//apis
import { SetMetaDataDisplay } from "../components/eui/MetaData";
import { getBySlug, getLatest } from "../api/pagesEuiApi";
import { getBySlugAdmin } from "../api/pagesApi";
//store
import { updateRegion } from "./../store/region";
//helpers
import { updateTitle } from '../helpers/titleHelper';

export default function Page() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [pageData, setPageData] = useState({});
    const [heroClass, setHeroClass] = useState("");
    const [latestResources, setLatestResource] = useState([]);
    const paths = window.location.pathname.split('/').filter(a => a != '');
    const newpath = paths[0] == 'can' && paths.length > 1 ? paths[1] : paths[0];

    const getPageData = async () => {
        const response = await getBySlug(newpath);
        if (response.data.success) {
            loadPageData(response.data.data);

        } else {
            getBySlugAdmin(newpath).then(response => {
                if (response.data.success == false) {
                    navigate("/not-found");
                }
                loadPageData(response.data.data);
            });
        }
    };

    const loadPageData = (dataObj) => {
        //if (response.data.data.visibility != true) { navigate("/not-found") }
        setPageData(dataObj);
        if (dataObj.regionIds.length > 0) {
            if (dataObj.regionIds.filter(a => a == 3).length > 0) {
                dispatch(updateRegion(3));//canada
            } else if (dataObj.regionIds.filter(a => a == 2).length > 0) {
                dispatch(updateRegion(2));//US
            }
        }
        setLoading(false);
        if (dataObj.metadata) {
            let metaDataObj = JSON.parse(dataObj.metadata);
            updateTitle(metaDataObj.title != '' ? metaDataObj.title : dataObj.title);
            metaDataObj.metaContentType = "article";
            SetMetaDataDisplay(metaDataObj);
        } else {
            updateTitle(dataObj.title);
        }
        if (dataObj.heroImageType == null)
            setHeroClass(" knowledge-no-img-detail");
        else {
            if (dataObj.heroMedia == null) setHeroClass(" knowledge-no-img-detail");
            else if (dataObj.heroImageType == 0)
                setHeroClass(" knowledge-styled-hero");
        }

        //get latest resources
        getLatest(dataObj.baseId).then(response => {
            if (response.data.success) {
                setLatestResource(response.data.data);
            }
        });
    }

    useEffect(() => {
        document.title = pageData.title;
    }, [pageData.title]);

    useEffect(() => {
        getPageData().catch((error) => {
            console.log(error);
        });
    }, [newpath]);

    return (
        <AppLayout>
            {loading ? (
                <FullPageAjaxLoader show={true} />
            ) : (
                <div className={"full-page-wrap" + heroClass} id="content">
                    <HeroSection
                        heroImageType={pageData.heroImageType}
                        heroImageMedia={pageData.heroMedia}
                    />
                    <div className="knowledge-detail-wrapper">
                        {pageData.heroImageType === 0 && (
                            <div className="section-angle-wrap tl-br">
                                <div className="section-angle">&nbsp;</div>
                            </div>
                        )}

                        <div className="knowledge-detail-inner custom-page">
                            <div className="knowledge-detail-center">
                                {Array.isArray(latestResources) &&
                                    latestResources.length > 0 && (
                                        <LatestResources resources={latestResources} />
                                    )}
                            </div>
                            <div className="knowledge-detail-page-section">
                                <div className="custom-center mod-center">
                                    <h1 className="knowledge-detail-headline">
                                        {pageData.title}
                                    </h1>
                                    {pageData.heroImageType === 0 ? (
                                        <div className="knowlege-detail-content-wrapper">
                                            <Modules
                                                moduleData={
                                                    pageData?.modules != null && pageData?.modules != ''
                                                        ? JSON.parse(pageData.modules)
                                                        : []
                                                }
                                            />
                                        </div>
                                    ) : (
                                        <Modules
                                            moduleData={
                                                pageData?.modules != null && pageData?.modules != ''
                                                    ? JSON.parse(pageData.modules)
                                                    : []
                                            }
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </AppLayout>
    );
}


const HeroSection = ({ heroImageType, heroImageMedia }) => {
    const [heroSection, setHeroSection] = useState(null);

    useEffect(() => {
        setHeroSection({
            heroImageType,
            heroImageMedia,
        });
    }, [heroImageType]);

    switch (heroImageType) {
        case 0:
            return <HeroTextural media={heroSection?.heroImageMedia} />;
        case 1:
            return <HeroNoCrop media={heroSection?.heroImageMedia} />;
        default:
            return null;
    }
};

const HeroNoCrop = ({ media }) => {
    return (
        <div className="detail-hero-cropimg">
            <div className="detail-hero-image">
                <img
                    className="blur-image"
                    src={media?.previewsrc}
                    alt={media?.alttext + " background"}
                />
                <img
                    className="small-img"
                    src={media?.previewsrc}
                    alt={media?.alttext}
                />
            </div>
            <div className="decoration-angle-wrap">
                <div className="decoration-angle">&nbsp;</div>
            </div>
        </div>
    );
};

const HeroTextural = ({ media }) => {
    return (
        <div className="detail-hero-highlight-section">
            <div className="detail-hero-highlight-section-background">
                <img src={media?.previewsrc} alt={media?.alttext || 'image'} />
            </div>
        </div>
    );
};
