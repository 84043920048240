import api from '../utils/adminApi';

//public APIs
export const details = (id) => {
    return api.get(`/api/press-releases/details/${id}`);
}

export const filter = (filterParams) => {
    return api.post(`/api/press-releases/filter`, filterParams);
}

export const getBySlug = (slug) => {
    return api.get(`/api/press-releases/get-by-slug/${slug}`);
}

//admin APIs
export const add = (item) => {
    return api.post('/api/admin/press-releases/add', item);
}

export const getByBaseId = (baseid) => {
    return api.get(`/api/admin/press-releases/get/${baseid}`);
}

export const remove = (id) => {
    return api.get('/api/admin/press-releases/delete/' + id);
}

export const getSlug = (id) => {
    return api.get(`/api/admin/press-releases/get-slug/${id}`);
}

export const getVersions = (id) => {
    return api.get(`/api/admin/press-releases/get-versions/${id}`);
}