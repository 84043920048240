import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import AjaxLoader from '../../../components/AjaxLoader.js';
import { getUsers, deleteUser } from '../../../api/adminuserapi.js'

export default function UserIndexPage() {

    const [loadingData, setLoadingData] = useState(true);
    const [users, setUsers] = useState([]);
    const [shouldDelete, setShouldDelete] = useState(null);

    useEffect(() => {
        getUsers()
            .then((response) => {
                if (response.data.success)
                    setUsers(response.data.data)
                setLoadingData(false);
            });
    }, [])

    const handleDelete = (userid) => {
        deleteUser(userid);
        setShouldDelete(null);
        setLoadingData(true);
        getUsers()
            .then((response) => {
                if (response.data.success) {
                    setUsers(response.data.data);
                }
                setLoadingData(false);
            });
    }

    const UsersTable = ({ users }) => {

        return (
            <>
                {shouldDelete != null && (
                    <div className="admin-modal-overlay">
                        <div className="admin-modal admin-add-role">
                            <div className="admin-modal-scroll">
                                <button
                                    className="admin-modal-close"
                                    onClick={() => {
                                        setShouldDelete(null);
                                    }}
                                >
                                    <span className="material-symbols-outlined">close</span>
                                </button>
                                <h2 className="admin-modal-heading">
                                    Are you sure you want to delete the User "{shouldDelete.title}" ? This cannot be undone!
                                </h2>
                                <div className="admin-buttons">
                                    <button
                                        className="admin-floating-button"
                                        onClick={() => {
                                            setShouldDelete(null);
                                        }}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="admin-floating-button power"
                                        onClick={() => handleDelete(shouldDelete.id)}
                                    >
                                        Delete User
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <table className="default-table">
                    <thead>
                        <tr><th className="">Email</th>
                            <th className="">Last Login</th>
                            <th className="">&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.length > 0 && users.map((user, idx) =>
                            <tr key={'user_' + idx}>
                                <td>{user.email}</td>
                                <td>{user.lastLogin == null ? "--" : new Date(user.lastLogin).toLocaleString()}</td>
                                <td className="admin-table-action-cell">
                                    <Link
                                        to={'/siteadmin/users/edit/' + user.id}
                                        className="material-symbols-outlined"
                                    >
                                        edit
                                    </Link>
                                    <div onClick={() => {
                                        setShouldDelete({
                                            id: user.id,
                                            title: user.email,
                                        })
                                    }}
                                        className="material-symbols-outlined"
                                        style={{ cursor: "pointer" }}
                                    >
                                        delete
                                    </div>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table></>);
    }

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                <div className="admin-top-bar-left">
                    <Link className="admin-top-bar-back" to="/siteadmin/">
                        Back
                    </Link>
                </div>
            </div>
            <div className="admin-page-title">
                <div className="admin-page-title-wrap">
                    <h1 className="admin-page-heading">Users</h1>
                    <Link
                        className="admin-button power"
                        to="/siteadmin/users/add"
                    >
                        Add User
                    </Link>
                </div>
            </div>
            <div className="admin-page-content">
                {loadingData ? <AjaxLoader /> : <UsersTable users={users} />}
            </div>
        </div>
    );
}