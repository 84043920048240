import api from '../utils/adminApi';

export const getUsers = () => {
  return api({
    url: '/api/adminusers/get-all',
    method: 'GET',
  })
}

export const getRoles = () => {
  return api({
    url: '/api/adminusers/get-roles',
    method: 'GET',
  })
}

export const getUserDetails = (userId) => {
  return api({
    url: '/api/adminusers/get/' + userId,
    method: 'GET'
  })
}

export const deleteUser = (userId) => {
    return api({
        url: '/api/adminusers/delete/' + userId,
        method: 'GET'
    })
}

export const saveUserDetails = (user) => {
  let url = '/api/adminusers/';
  if (user.id === null) url += 'add';
  else url += 'edit';
  return api.post(url, user);
}

export const getLoginUserDetails = () => {
    return api({
        url: '/api/adminusers/get-login-user',
        method: 'GET'
    })
}