export default function getRegion() {
    var region = localStorage.getItem('region');
    if (region == null) { region = 2; } else if (typeof region == 'string') { region = parseInt(region); }
    return region;
};

export const regions = [
    { value: 2, title: 'US' },
    { value: 3, title: 'CA' },
    { value: 4, title: 'INT' }
];