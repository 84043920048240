import React, { useState } from 'react';
import './Detail.css';
import './Protivate.css';
import ProtivateLogo from '../assets/protivate-ca-new.png';
import ProtivateLogoOverlay from '../assets/rocket-seeds-protivate-now-popup.png';
import ProtivateImage from '../assets/protivate-image-1000.webp';
import ProtivateBanner from '../assets/protivate-banner-1500.webp';
import { Button } from '../components/Button/Button.jsx';
import ProtivateDri from '../assets/protivate-dri.png';
import ResearchSliderProtivate from '../components/ResearchSlider/ResearchSliderProtivateca.js';
import Submenu from '../components/Navigation/SubNavigation.js';
import ResourcesRelatedSection from '../components/ResourcesAndRelatedContent/ResourceRelatedSection.js';
import OpennDropdown from '../assets/red_open_in_new.svg';
import downArrow from '../assets/red_expand_more.svg';

function ProtivateCA() {
    const sections = [
        { title: "Overview", id: "overview" },
        { title: "Features & Benefits", id: "features-benefits" },
        { title: "Research", id: "research" },
        { title: "Formulations", id: "formulations" },
        { title: "Resources", id: "resources" },
    ];

    const [showModal, setShowModal] = useState(localStorage.getItem('protivate-ca-modal') == 1 ? false : true);
  document.title = "PROTIVATE Plant Smarter With A Dual-Purpose Seed Enhancer - Koch Agronomic Services";
    return (
      <div>
        <div className="full-page-wrap red protivate protivate-ca" id="content">
      <Submenu sections={sections} />
      <div className="product-intro-div" id="overview">
        <div className="product-intro-center">
          <div className="product-intro-info">
            <div className="product-intro-badge">
               <img src={ProtivateLogo} alt="Protivate Marquee" width="500" height="312"/>
            </div>
           <h1 className="product-intro-headline">PROTIVATE&trade; Nutritional Seed Enhancer</h1>
            <p className="product-intro-text">
                This dual-purpose seed enhancer that provides seed lubricity and essential nutrients to 
                improve germination, support early and uniform crop emergence, and increase plant vigor 
                while also providing excellent plantability.
            </p>
            <div className="product-intro-button-wrap">
                <div className="menu-wrap product-label-dropdown">
                    <ul className="menu">
                        <li className="menu-item">
                            <span>Product Label</span>
                            <span> <img src={downArrow} alt="Protivate Dropdown Arrow" width="12" height="7"/></span>
                            <ul className="drop-menu">
                                <li className="drop-menu-item">
                                    <a href="https://kochagronomicservices.com/doccenter/17959f73-fe78-41ae-8d2c-8d69562285f1" target="_blank">NU4-DRI Product label</a>
                                    <span> <img src={OpennDropdown} alt="Protivate Share" width="16" height="16" /></span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
                <div className="menu-wrap">
                    <ul className="menu">
                        <li className="menu-item">
                            <span>SDS</span>
                            <span> <img src={downArrow} alt="Protivate Dropdown Arrow" width="12" height="7" /></span>
                            <ul className="drop-menu">
                                <li className="drop-menu-item">
                                    <a href="https://kochagronomicservices.com/doccenter/5b045489-e15a-49bd-81b5-cbb69503dd2a" target="_blank">NU4-DRI SDS (EN)</a>
                                    <span> <img src={OpennDropdown} alt="Protivate Share" width="16" height="16" /></span>
                                </li>
                                <li className="drop-menu-item">
                                     <a href="https://kochagronomicservices.com/doccenter/e0587974-b6f8-4535-9b21-92d6b68232e0" target="_blank">
                                        NU4-DRI SDS (FR)
                                    </a>
                                    <span><img src={OpennDropdown} alt="Protivate Share" width="16" height="16" /></span>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
          </div>
          <div className="product-intro-image-wrap">
             <div className="product-intro-image">
                   <img src={ProtivateImage} alt="detail view of Protivate" width="1000" height="873" />
            </div>
          </div>
        </div>
      </div>
      <div className="product-highlight-section-background-detail">&nbsp;</div>
      <div className="product-highlight-section reverse" >
          <div className="product-highlight-image-wrap">
               <img src={ProtivateBanner} alt="Protivate" width="1500" height="912" />
        </div>
              <div className="product-highlight-section-center" id="features-benefits">
           <div className="product-card-wrap">
            <div className="product-card">
              <span className="product-card-title">Early Season Nutrition</span>
              <span className="product-card-text">
                By providing supplemental nutrition needed for crop establishment, PROTIVATE can 
                allow plants to thrive in the early stages of crop growth.
              </span>
              <span className="product-card-decoration" style={{background: '#FA1A38'}}>&nbsp;</span>
            </div>
            <div className="product-card">
              <span className="product-card-title">Talc Replacement</span>
              <span className="product-card-text">
                PROTIVATE works as a seed-drying source, even in high humidity conditions, to improve 
                singulation and flowability — eliminating the need for talc.</span>
              <span className="product-card-decoration" style={{background: '#D1112B'}}>&nbsp;</span>
             </div>
             <div className="product-card">
              <span className="product-card-title">Critical Micronutrients</span>
              <span className="product-card-text">
                    As a highly concentrated nutrient source for young crops, PROTIVATE can help combat nutrient 
                    deficiencies associated with cold, wet soils — alleviating post-planting uncertainties 
                    surrounding nutrient availability.
              </span>
              <span className="product-card-decoration" style={{background: '#F47E8D'}}>&nbsp;</span>
            </div>
           
            <div className="product-card">
              <span className="product-card-title">Seed Flow and Lubricity</span>
              <span className="product-card-text">In the planter box, PROTIVATE provides flowability and encourages better seed singulation, helping to avoid costly skips and doubles.</span>
              <span className="product-card-decoration" style={{background: '#810011'}}>&nbsp;</span>
            </div>
          </div>
          <div className="product-highlight-section-content">
            <p className="product-highlight-section-small">Features &amp; Benefits</p>
            <p className="product-highlight-section-headline">Plant Smarter With A Dual-Purpose Seed Enhancer</p>
            <p className="product-highlight-section-text">
            Each season brings a different set of challenges that can leave soils cold and wet — potentially restricting 
            access to critical nutrients. PROTIVATE nutritional seed enhancer is designed to give young crops the 
            nutrients needed for optimal crop establishment while also eliminating the need for talc. By providing 
            the right nutrition, PROTIVATE can enable your plants to thrive in their early stages of growth. 
            </p>
          </div>
         </div>
         <div className="white-section-angle-wrap"><div className="white-section-angle">&nbsp;</div></div>
         <div className="light-red-section-angle-wrap"><div className="light-red-section-angle">&nbsp;</div></div>
      </div>
     
        <div className="research-section" id="research">
            <div className="research-section-center">
                <div className="research-content">
                    <p className="research-content-small">Research</p>
                    <p className="research-content-title">Critical Nutrients At The Right Time</p>
                    <p className="research-content-text">By applying the right nutrients directly to the seed, they are then delivered to the right place (near growing roots) and at the right time (during the seedling’s most vulnerable growth stages) — giving plants a better chance to withstand early season cold and water stress.</p>
                </div>
                <div className="research-rotator-wrap">
                    <ResearchSliderProtivate />
                </div>
            </div>
        </div>
      <div className="formulations-section" id="formulations">
        <div className="formulations-section-center">
          <div className="formulations-content">
            <p className="formulations-content-small">Formulations</p>
            <p className="formulations-content-headline">Flexible Formulation</p>
            <p className="formulations-content-text">
             PROTIVATE supports various application methods that can fit the equipment available for growers or retailers. This provides a flexible way to put nutrition right where the crop needs it. PROTIVATE contains nitrogen, phosphate, manganese and zinc. These nutrients help support shoot and root growth during crop establishment, photosynthesis and drive the metabolic reactions needed for a plant to reach optimal yield potential.
            </p>
          </div>
          <div className="protivate-formulation-dri">
              <div className="formulations-product">
                <div className="formulations-logo">
                  <img src={ProtivateDri} alt="Protivate Dri" width="115" height="115"/>
                </div>
                <p className="formulations-product-text formulations-product-box-title">NU4-DRI</p>
                <p className="formulations-product-text">
                   Most commonly used in corn and major row crops, NU4-DRI contains nitrogen, which is a 
                   critical component in the building blocks of proteins and is needed for the chlorophyll 
                   molecule to enable photosynthesis.  By providing proper nutrition to the seed, plants 
                   can better withstand early-season cold and water stress while progressing through the 
                   vegetative growth stage. Your favorite partner in the planter box, NU4-DRI is the right 
                   seed treatment to address the critical, early stages of crop growth to maximize yield.
                </p>
                <div className="formulations-product-action-wrap">
                                    <div className="menu-wrap product-label-dropdown">
                                        <ul className="menu">
                                            <li className="menu-item">
                                                <span>Product Label</span>
                                                <span> <img src={downArrow} alt="Protivate Dropdown Arrow" width="12" height="7" /></span>
                                                <ul className="drop-menu">
                                                    <li className="drop-menu-item">
                                                        <a href="https://kochagronomicservices.com/doccenter/17959f73-fe78-41ae-8d2c-8d69562285f1" target="_blank">NU4-DRI Product label</a>
                                                        <span> <img src={OpennDropdown} alt="Protivate Share" width="16" height="16" /></span>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="menu-wrap">
                                        <ul className="menu">
                                            <li className="menu-item">
                                                <span>SDS</span>
                                                <span> <img src={downArrow} alt="Protivate Dropdown Arrow" width="12" height="7" /></span>
                                                <ul className="drop-menu">
                                                    <li className="drop-menu-item">
                                                        <a href="https://kochagronomicservices.com/doccenter/5b045489-e15a-49bd-81b5-cbb69503dd2a" target="_blank">NU4-DRI SDS (EN)</a>
                                                        <span> <img src={OpennDropdown} alt="Protivate Share" width="16" height="16" /></span>
                                                    </li>
                                                    <li className="drop-menu-item">
                                                        <a href="https://kochagronomicservices.com/doccenter/e0587974-b6f8-4535-9b21-92d6b68232e0" target="_blank">
                                                            NU4-DRI SDS (FR)
                                                        </a>
                                                        <span><img src={OpennDropdown} alt="Protivate Share" width="16" height="16" /></span>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <ResourcesRelatedSection id={21} />
            </div>
            {showModal &&
                <div class="protivate-ca-modal">
                    <div class="protivate-ca-modal-body">
                        <button type="button" class="close-protivate-ca-modal" onClick={() => { localStorage.setItem('protivate-ca-modal', 1); setShowModal(false); } }>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.1516 7.17046L1.80803 11.514C1.64552 11.6765 1.43868 11.7578 1.18752 11.7578C0.936365 11.7578 0.729528 11.6765 0.567014 11.514C0.404499 11.3515 0.323242 11.1447 0.323242 10.8935C0.323242 10.6424 0.404499 10.4355 0.567014 10.273L4.91058 5.92944L0.567014 1.58587C0.404499 1.42335 0.323242 1.21651 0.323242 0.965356C0.323242 0.714197 0.404499 0.507361 0.567014 0.344846C0.729528 0.182331 0.936365 0.101074 1.18752 0.101074C1.43868 0.101074 1.64552 0.182331 1.80803 0.344846L6.1516 4.68842L10.4952 0.344846C10.6577 0.182331 10.8645 0.101074 11.1157 0.101074C11.3668 0.101074 11.5737 0.182331 11.7362 0.344846C11.8987 0.507361 11.98 0.714197 11.98 0.965356C11.98 1.21651 11.8987 1.42335 11.7362 1.58587L7.39262 5.92944L11.7362 10.273C11.8987 10.4355 11.98 10.6424 11.98 10.8935C11.98 11.1447 11.8987 11.3515 11.7362 11.514C11.5737 11.6765 11.3668 11.7578 11.1157 11.7578C10.8645 11.7578 10.6577 11.6765 10.4952 11.514L6.1516 7.17046Z" fill="white" />
                            </svg>
                        </button>
                        <div class="protivate-ca-modal-row">
                            <img src={ProtivateLogoOverlay} alt="Protivate Marquee" width="500" height="312" />
                        </div>
                        <p class="seedsToProtivate">ROCKET SEEDS™ is now PROTIVATE™</p>
                    </div>
                </div>
            }
       </div>
  );
}

export default ProtivateCA;