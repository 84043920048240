import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PropTypes from "prop-types";

import EditPageWrapper from "../../../components/admin/Editor/EditPageWrapper";
import {
  getRoles,
  getUserDetails,
  saveUserDetails,
} from "../../../api/adminuserapi";

import FullPageAjaxLoader from "../../../components/FullPageAjaxLoader";

const defaultUser = {
  id: 0,
  email: "",
  password: "",
  userRoles: [{}],
};

export default function UserEditPage() {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [error, setError] = useState(null);

  const [user, setUser] = useState({
    ...defaultUser,
    id: userId == null ? null : userId,
  });

  const onSave = () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!user.email.trim()) {
      setError("Email is required.");
      return;
    }
    if (!emailRegex.test(user.email)) {
      setError("Please enter a valid email address.");
      return;
    }

    saveUserDetails(user).then((response) => {
      if (response.data.success) {
        navigate("/siteadmin/users");
      } else {
        setError(response.data.message);
      }
    });
  };

  const handleRoleChange = (e) => {
    if (e.target.checked) {
      setUser({
        ...user,
        userRoles: [...user.userRoles, { siteRoleId: e.target.value }],
      });
    } else {
      setUser({
        ...user,
        userRoles: [
          ...user.userRoles.filter((x) => x.siteRoleId !== e.target.value),
        ],
      });
    }
  };

  useEffect(() => {
    if (user.id !== null) {
      getUserDetails(userId).then((response) => {
        if (response.data.success) {
          setUser(response.data.data);
          getRoles().then((response) => {
            if (response.data.success) setRoles(response.data.data);
            setIsLoading(false);
          });

        }
      });
    }
    getRoles().then((response) => {
      if (response.data.success) setRoles(response.data.data);
      setIsLoading(false);
    });
  }, []);

  return (
    <>
      {isLoading && <FullPageAjaxLoader show={isLoading} />}
      <EditPageWrapper
        itemId={userId}
        title={"Users"}
        route={"/siteadmin/users"}
        onSave={onSave}
        onCancel={() => navigate("/siteadmin/users")}
      >
        {error && <p className="error-message">{error}</p>}
        <label className="admin-page-desc" htmlFor="email">
          Email
        </label>
        <input
          id="email"
          className="admin-default-input-text"
          type="email"
          required
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          value={user.email}
          disabled={user.id !== null}
        />

        <label className="admin-page-desc" htmlFor="password">
          Password
        </label>
        <input
          id="password"
          className="admin-default-input-text"
          type="password"
          placeholder={
            userId == null
              ? "A random value will be assigned to password if left blank"
              : "No change to password if left blank"
          }
          onChange={(e) => setUser({ ...user, password: e.target.value })}
          value={user.password}
        />

        <div className="admin-section-row">
          <div className="admin-section-cell full">
            <p className="admin-section-headline">Select Role(s)</p>
            <div className="admin-column-wrap">
              <div className="admin-column">
                {roles.map((role, idx) => (
                  <label className="admin-inline-label" key={idx}>
                    <input
                      className="admin-inline-checkbox"
                      id={"itemId_" + idx}
                      type="checkbox"
                      checked={
                        user.userRoles.length > 0 &&
                        user.userRoles.filter((x) => x.siteRoleId === role.id)
                          .length > 0
                      }
                      value={role.id}
                      onChange={(e) => handleRoleChange(e)}
                    />
                    <span className="admin-inline-label-text">{role.name}</span>
                  </label>
                ))}
              </div>
            </div>
          </div>
        </div>
      </EditPageWrapper>
    </>
  );
}
