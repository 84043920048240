import React from 'react';
import './Error.css';
import Navigation from '../../components/Navigation/Navigation.js';
import Footer from '../../components/Footer/Footer.js';

export default function NotFound() {
  return (
    <div className="full-page-wrap error-page-wrapper">
      <Navigation />
      <div className="error-page-content">
        <div className="error-page-content-center">
          <h1 className="error-page-heading">Page Not Found</h1>
          <p className="error-page-text">This page is missing. Please check the URL or use the navigation above to find what you are looking for.</p>
        </div>
      </div>
      <Footer />
    </div>
  )
}
