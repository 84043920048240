import React from 'react';
import './Admin.css';
import AdminLinkTile from '../../components/admin/admin-link-tile';

function Admin() {

    return (
        <div className="admin-page-wrap">
            <div className="admin-top-bar-wrap">
                &nbsp;
            </div>
            <div className="admin-page-content">
                <div className="admin-link-tile-wrap">
                    <AdminLinkTile
                        href="/siteadmin/products"
                        icon="flowsheet"
                        name="Products"
                    />

                    <AdminLinkTile
                        href="/siteadmin/knowledge-center"
                        icon="lightbulb"
                        name="Knowledge Center"
                    />
                    <AdminLinkTile
                        href="/siteadmin/product-labels"
                        icon="folder_copy"
                        name="Product Labels/SDSs"
                    />
                    <AdminLinkTile
                        href="/siteadmin/reps-retailers"
                        icon="contacts"
                        name="Reps & Retailers"
                    />
                    <AdminLinkTile
                        href="/siteadmin/press-releases"
                        icon="campaign"
                        name="Press Releases"
                    />
                    <AdminLinkTile
                        href="/siteadmin/pages"
                        icon="note_stack"
                        name="Pages"
                    />
                    <AdminLinkTile
                        href="/siteadmin/document-library"
                        icon="folder_open"
                        name="Document Library"
                    />
                    <AdminLinkTile
                        href="/siteadmin/forms"
                        icon="note_stack"
                        name="Forms"
                    />
                    <AdminLinkTile
                        href="/siteadmin/users"
                        icon="group"
                        name="Users"
                    />
                    <AdminLinkTile
                        href="/siteadmin/redirects"
                        icon="arrow_split"
                        name="Redirects"
                    />
                </div>
            </div>
        </div>
    );
}

export default Admin;