import './Layout.css';
import { useSelector } from 'react-redux';
import AdminDrawer from '../components/admin/drawer';
import Navigation from '../components/Navigation/Navigation';
import Footer from '../components/Footer/Footer';
import ScrollToTop from '../components/ScrollToTop';
import SessionTimeoutModal from "../components/admin/Editor/SessionTimeoutModal";
const Layout = ({ children }) => {
	const { isAuthenticated } = useSelector((state) => state.auth);
	//admin-menu
	return (
		<div className={"admin-full-wrap" + (isAuthenticated ? " admin-menu" : "")}>
			<SessionTimeoutModal></SessionTimeoutModal>
			<AdminDrawer isEUI={true} />
			<Navigation />
			<ScrollToTop />
			{children}
			<Footer />
		</div>
  )
};

export default Layout;