import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

//Module Types
import FreeformModule from './FreeformModule';
import TextCalloutModule from './TextCalloutModule';
import TwoColumnTextModule from './TwoColumnTextModule';
import TwoColumnBlockModule from './TwoColumnBlockModule';
export default function Modules({ moduleData }) {

  const [modules, setModules] = useState(moduleData?.modules);

  useEffect(() => {
    setModules(moduleData?.modules);
  }, [moduleData]);

  return (<>
      {modules && modules.map((module, idx) => 
        <div key={idx} className="module">
            <RenderModule module={module} />
        </div>
    ) }
  </>)
}

Modules.propType = {
  moduleData: PropTypes.array,
}

const RenderModule = ({ module }) => {
  switch (module.type) {
    case 1:
      return <TextCalloutModule module={module} />;
    case 2:
      return <FreeformModule module={module} />;
    case 3:
      return <TwoColumnTextModule module={module} />;
    case 4:
      return <TwoColumnBlockModule module={module} />;
    default:
      return null;
  }
}
