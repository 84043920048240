import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./ContactReps.css";
import MapBackground from "../../assets/map.png";
import { useNavigate } from "react-router-dom";
import { updateRegion } from "../../store/region";
function ContactReps() {
    document.title = "Find a Rep or Retailer - Koch Agronomic Services";
    const navigate = useNavigate();
    const performSearch = (value) => {
        navigate("/reps-retailers", { state: { formData: value } });
    };
    return (
        <div className="full-page-wrap contactreps" id="content">
            <div className="find-rep-wrap">
                <div className="find-rep-map">
                    <div className="find-rep-map-background">
                        <img src={MapBackground} alt="Map of the World" />
                    </div>
                    <div className="find-rep-center">
                        <h2 className="find-rep-headline">Find a Rep or Retailer</h2>
                        <p className="find-rep-desc">
                            With our network of knowledgeable representatives, retailers and
                            distributors, you can be confident you will get the best solution
                            for your unique needs. Find your nearest Koch Agronomic Services,
                            LLC, representative.
                        </p>
                        <FindRepForm
                            performSearch={(value) => {
                                performSearch(value);
                            }}
                        />
                    </div>
                </div>
            </div>

        </div>
    );
}

export const FindRepForm = ({ performSearch }) => {
    const currentRegion = useSelector((state) => state.region.currentRegion);
    const dispatch = useDispatch();

    const [value, setValue] = useState({
        label: "",
        zip: "",
    });
    const [validation, setValidation] = useState("");

    const options = [
        { label: "", value: "", id: 0 },
        { label: "I am a grower", value: "Retailers", id: 2 },
        { label: "I am a retailer", value: "Reps", id: 1 },
    ];

    function handleSelect(event) {
        setValue({ ...value, label: event.target.value });
        if (event.target.value) {
            setValidation("");
        }
    }

    function handleCountryChange(value) {
        dispatch(updateRegion(value));
    }

    function handleZipChange(event) {
        setValue({ ...value, zip: event.target.value });
    }
    function handleKeyPress(event) {
        if (event.key === "Enter") {
            handleSearch();
        }
    }
    const handleSearch = () => {
        if (!value.label || !value.zip) {
            setValidation("Please select a value and enter Zipcode");
            return;
        }
        setValidation("");
        performSearch(value);
    };
    return (
        <div className="find-rep-form">
            <div className="find-rep-row mb-1">
                <div className="find-rep-form-cell">
                    <label className="find-rep-form-label" htmlFor="find-rep-country">
                        Select Country
                    </label>
                    <select
                        value={currentRegion}
                        className="find-rep-form-select"
                        id="find-rep-country"
                        onChange={(event) => { handleCountryChange(parseInt(event.target.value)) }}
                    >
                        <option value={2}>U.S.</option>
                        <option value={3}>Canada</option>
                        {/* <option value={4}>International</option> */}
                    </select>
                </div>
                <div className="find-rep-form-cell">
                    <label className="find-rep-form-label" htmlFor="find-rep-who">
                        Who Are You?
                    </label>
                    <select
                        className="find-rep-form-select"
                        id="find-rep-who"
                        onChange={handleSelect}
                    >
                        {options.map((option) => (
                            <option key={option.id} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                <div className="find-rep-form-cell">
                    <label className="find-rep-form-label" htmlFor="find-rep-search">
                        Search by {currentRegion === 3 ? "Postal Code" : "Zip"}
                    </label>
                    <input
                        className="find-rep-form-input"
                        id="find-rep-search"
                        type="Search"
                        value={value.zip}
                        onChange={handleZipChange}
                        onKeyDown={handleKeyPress}
                    />
                    <svg
                        className="find-rep-form-mag"
                        xmlns="http://www.w3.org/2000/svg"
                        height="24"
                        viewBox="0 -960 960 960"
                        width="24"
                    >
                        <path
                            fill="currentColor"
                            d="M380-320q-109 0-184.5-75.5T120-580q0-109 75.5-184.5T380-840q109 0 184.5 75.5T640-580q0 44-14 83t-38 69l224 224q11 11 11 28t-11 28q-11 11-28 11t-28-11L532-372q-30 24-69 38t-83 14Zm0-80q75 0 127.5-52.5T560-580q0-75-52.5-127.5T380-760q-75 0-127.5 52.5T200-580q0 75 52.5 127.5T380-400Z"
                        />
                    </svg>
                </div>
            </div>
            <div className="error-message">{validation}</div>
            <div className="find-rep-row">
                <div className="find-rep-form-cell">
                    <button className="find-rep-form-submit" onClick={handleSearch}>
                        Search {value.label ? `for ${value.label}` : ""}
                    </button>
                </div>
            </div>
        </div>
    );
};
export default ContactReps;
