import api from '../utils/adminApi';

export const archive = (id) => {
    return api.get('/api/admin/forms/archive/' + id);
}

export const details = (id) => {
    return api.get(`/api/admin/forms/details/${id}`);
  }

  export const filter = (filterParams) => {
    return api.post(`/api/admin/forms/filter`, filterParams);
}