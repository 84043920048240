import React, { useEffect, useState } from "react";
import FatherSon from "../../assets/father-son.jpg";
import { getProductDetails } from "../../api/productsApi";
import { Link } from "react-router-dom";
import { SetMetaDataDisplay } from "../../components/eui/MetaData";

export default function ResourcesRelatedSection({ id }) {
  const [items, setItems] = useState([]);

  useEffect(() => {
    const getDetails = async () => {
      const response = await getProductDetails(id);
      if (response.data.success) {
        const data = response.data.data;
        data.metadata = safelyParseJson(data.metadata);
        if(data.metadata && data.metadata !== 'null'){
          SetMetaDataDisplay(data.metadata);
        }
        data.resources = safelyParseJson(data.resources);
        data.related = safelyParseJson(data.related);
        setItems(data);
      }
    };
    getDetails();
  }, []);

  const safelyParseJson = (jsonString) => {
    try {
      return JSON.parse(jsonString);
    } catch (e) {
      return null;
    }
  };
  if (items && items.resources === null && items.related === null) {
    return null;
  }
  return (
    <div className="resources-section" id="resources">
      <div className="resources-section-center">
        {items && items.resources && items.resources.length > 0 && (
          <Resources data={items.resources} />
        )}
        {items && items.related && items.related.length > 0 && (
          <Related data={items.related} />
        )}
      </div>
    </div>
  );
}

const Resources = ({ data }) => {
  return (
    <div className="resources-list-content">
      <p className="resources-list-small">Resources &amp; Related Content</p>
      {data.map((resources) => (
        <div className="resource-list-section">
          <p className="resource-list-header">{resources.headerName}</p>
          {resources.selectedResources.map((item) => (
            <a
              className="resource-list-item"
              href={item.customFilePath ? item.customFilePath : `/doccenter/${item.uid}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <span className="resource-list-item-title">{item.name}</span>
              <span className="resource-list-item-action">View</span>
            </a>
          ))}

        </div>
      ))}

    </div>
  );
};

const Related = ({ data }) => {
  return (
    <div className="resources-story-wrap">
      {data.map((item) => (
        <Link className="resources-story" to={`/knowledge-center/${item.slug}`}>
          <span className="resources-story-image">
          {item.heroMedia?.previewsrc && (
            <span className="resources-story-image">
              <img
                src={item.heroMedia.previewsrc}
                alt={item.heroMedia.altText || 'Image'}
              />
            </span>
          )}
          </span>
          <span className="resources-story-title">{item.title}</span>
        </Link>
      ))}
    </div>
  );
};
