import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { keepAlive } from '../../../api/sessionApi.js';

export default function SessionTimeoutModal() {
    const [loaded, setLoaded] = useState(false);
    const [show, setShow] = useState(false);
    const sessionTimeout = 4 * 60; //in minutes
    const minutesBeforeExpires = 2;
    useEffect(() => {
        if (loaded == false) {
            setLoaded(true);
            setTimeout(() => {
                setShow(true);
            }, (1000 * 60 * sessionTimeout) - minutesBeforeExpires);
        }
    }, []);

    const onClose = () => {
        setShow(false);
    }

    const stayLoggedIn = () => {
        keepAlive();
        onClose();
    }
    if (!show) { return null; }
    return (
        <div className="admin-modal-overlay">
            <div className={"admin-modal admin-add-role "}>
                <div className="admin-modal-scroll">
                    <button className="admin-modal-close" onClick={onClose}>
                        <span className="material-symbols-outlined">close</span>
                    </button>
                    <h2 className="admin-modal-heading">Session Timeout</h2>
                    <p>Your session is about to time out. Would you like to stay logged into your account?</p>
                    <div className="admin-modal-button-wrap">
                        <Link
                            className="admin-modal-button power"
                            style={{ textDecoration: "none" }}
                            onClick={stayLoggedIn}
                        >
                            Yes
                        </Link>
                        <Link
                            to={"/account/logout"}
                            className="admin-modal-button"
                            style={{ textDecoration: "none" }}
                            onClick={onClose}
                        >
                            No
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
