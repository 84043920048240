import localforage from 'localforage';

const indexstore = localforage.createInstance({
    name: "Koch",
    version: 1,
    storeName: "appStore",
    driver: localforage.INDEXEDDB,
})

const localstore = localforage.createInstance({
  name: "Koch",
    version: 1,
    storeName: "appLocalStore",
    driver: localforage.LOCALSTORAGE,
});

export { indexstore, localstore };
