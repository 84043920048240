import { useState } from 'react';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';

export const IntlPhoneInput = ({value, onChange}) => {

  return (
    <div>
      <PhoneInput
        defaultCountry="us"
        value={value}
        onChange={onChange}
      />
    </div>
  );
};