import React, { useState, useEffect } from 'react';

const CustomSelect = ({ options, placeholder, onChange, currentRegion }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState("US");
    //const [selectedValue, setSelectedValue] = useState(options.length > 0 ? options[0].text : '');

    const handleOptionClick = (value, text) => {
        setSelectedValue(text); // Set the selected text
        onChange(value, text); // Notify parent component with both value and text
        setIsOpen(false);
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };
    useEffect(()=>{
        if(currentRegion === 4){
            setSelectedValue('INT')
        }
        if(currentRegion === 3){
            setSelectedValue('CA')
        }
        if(currentRegion === 2){
            setSelectedValue('US')
        }
    },[currentRegion])
    return (
        <div className="custom-select-wrapper">
            <div className={`custom-select ${isOpen ? 'opened' : ''}`} onClick={toggleDropdown}>
                <span className="custom-select-trigger">{selectedValue}</span>
                <div className="custom-options">
                    {options.map((option, index) => (
                        <span
                            key={index}
                            className={`custom-option ${option.className}`}
                            data-value={option.value}
                            onClick={() => handleOptionClick(option.value, option.text)}
                        >
                            {option.text}
                        </span>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CustomSelect;
