import { createSlice } from '@reduxjs/toolkit';
import getRegion from '../utils/region';

const initialState = {
    currentRegion: getRegion(), 
    isChanging:false,
    getMegaMenu: false ,
    initialLoad:true 
};

const regionSlice = createSlice({
    name: 'region',
    initialState,
    reducers: {
        setRegion: (state, action) => {
            const newRegion = action.payload;
            state.currentRegion = newRegion;
            localStorage.setItem('region', newRegion);
            state.isChanging = true;
        },
        updateRegion: (state, action) => {
            const newRegion = action.payload;
            state.currentRegion = newRegion;
            localStorage.setItem('region', newRegion);
        },
        setIsChanging: (state, action) => {
            state.isChanging = false;
        },
        toggleMegaMenu: (state, action) => {  //toggling menu from home pages to nav
            state.getMegaMenu = action.payload;
        },
        setInitialLoad: (state,) => {
            state.initialLoad = false;
        },
        setRegionByURl:(state, action) => { //for marketing / funneling purposes if user hits a specifc link then we may want to change region for them
            const newRegion = action.payload;
            state.currentRegion = action.payload;
            localStorage.setItem('region', newRegion);
        }

    },
});

export const { setRegion, setIsChanging,toggleMegaMenu,setInitialLoad, setRegionByURl, updateRegion  } = regionSlice.actions;

export default regionSlice.reducer;
