import React, { useState } from 'react';
import './StepForm.css';
import { Button } from '../Button/Button.jsx';
import StepFormClose from '../../assets/step-form-close.svg';

export const StepOne = ({ onNext , step}) => {
    const [canNext, setCanNext] = useState(step.steps[step.step] != null);
    const [selected, setSelected] = useState(step.steps[step.step]);
    const next = () => {
        onNext(selected);
    };

    const showNext = (value) => {
        setSelected(value);
        setCanNext(true);
    }
    return (
     <section className={'stepForm'}>

            <div className="stepOne-formWrapper">
              <div className="mega-menu-container">
               <div className="mega-menu-container-inner">
                <p className="stepForm-questionList">Question
                    {/*{step.step}*/}
                </p>
                <p className="stepForm-question">What challenge are you facing?</p>
                <div className="radio-btn-row">
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(1); }} checked={selected == 1} />
                        <span className="step-form-label">Early Crop Growth Emergence</span>
                    </label>
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(2); }} checked={selected == 2} />
                        <span className="step-form-label">Early Season Nutrition</span>
                    </label>
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(3); }} checked={selected == 3} />
                        <span className="step-form-label">Micronutrient Deficiencies</span>
                    </label>
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(4); }} checked={selected == 4} />
                        <span className="step-form-label">Micronutrient Use Efficiency</span>
                    </label>
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(5); }} checked={selected == 5} />
                        <span className="step-form-label">Nitrogen Loss Protection</span>
                    </label>
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(6); }} checked={selected == 6} />
                        <span className="step-form-label">Nitrogen Use Efficiency</span>
                    </label>
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(7); }} checked={selected == 7} />
                        <span className="step-form-label">Planter Flowability</span>
                    </label>
                    <label className="step-form-radio">
                        <input type="radio" className="radio-btn" name="cropStage" onChange={() => { showNext(8); }} checked={selected == 8} />
                        <span className="step-form-label">Reducing Early-Season Stress</span>
                    </label>
                </div>
                </div>
                </div>
            </div>
            {canNext &&
                <div className="stepForm-btnWrapper">
                    <Button onClick={next} className="default-btn ml-auto"
                        label="Next" />
                </div>
            }
            </section>
       
  );
};