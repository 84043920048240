import React, { useState } from "react";
import Close from "../../assets/Icons/close-icon.png";
import BackArrow from "../../assets/Icons/arrow_back.png";
import StepFormClose from '../../assets/step-form-close.svg';

import { StepOne } from "../StepForm/StepOne.jsx";
import { StepTwo } from "../StepForm/StepTwo.jsx";
import { StepThree } from "../StepForm/StepThree.jsx";
import { StepFour } from "../StepForm/StepFour.jsx";
import { StepFive } from "../StepForm/StepFive.jsx";
import { StepSix } from "../StepForm/StepSix.jsx";
import { StepSeven } from "../StepForm/StepSeven.jsx";
import { useNavigate, useLocation } from "react-router-dom";
export default function ProductSelector() {
  const [step, setStep] = useState({ step: 1, steps: [] });
  const navigate = useNavigate();
  const location = useLocation();
  const nextStep = (data) => {
    var steps = step.steps;
    steps[step.step] = data;
    var next = step.step + 1;
    switch (step.step) {
      case 1:
      default:
        switch (data) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 8:
          default:
            //apply nutrients
            break;
          case 5:
          case 6:
            //what crop are you growing?
            next = 3;
            break;
          case 7:
            //seed enhancer
            break;
        }
        break;
      case 3: //what crops are you growing?
        if (step.steps[1] == 5 || step.steps[1] == 6) {
          next = 6;
        } else if (step.steps[2] == 2) {
          next = 5;
        }
        break;
      case 4: //what are you deficient in?
        if (step.steps[2] == 1) {
          next = 7;
        }
        break;
      case 5: //Will you be treating on-farm/at the planter?
        next = 7;
        break;
    }
    setStep({ ...step, steps: steps, step: next });
  };

  const prevStep = () => {
    var prev = step.step - 1;
    switch (step.step) {
      case 3:
        switch (step.steps[1]) {
          case 1:
          case 2:
          case 3:
          case 4:
          case 8:
          default:
            //apply nutrients
            break;
          case 5:
          case 6:
            //what crop are you growing?
            prev -= 1;
            break;
          case 7:
            //seed enhancer
            break;
        }
        break;
      case 5:
        //what crop are you growing?
        prev -= 1;
        break;
      default:
        break;
    }
    setStep({ ...step, step: prev });
  };

  const startOver = () => {
    setStep({ step: 1, steps: [] });
  };

  const handleClose = () => {
    if(location.state && location.state.internalRoute){
      navigate(-1);
    }
    else{
      navigate('/')
    }
  }
  return (
    <>
     <div className="close-btn-wrapper">
     <button id="stepClose" className="step-form-close" aria-label="step form close" onClick={handleClose}>
                <img src={StepFormClose} alt="Close" width="18" height="18" />
            </button>
      </div>
      <section>
        {step.step === 1 && <StepOne onNext={nextStep} step={step} />}
        {step.step === 2 && (
          <StepTwo onNext={nextStep} onPrev={prevStep} step={step} />
        )}
        {step.step === 3 && (
          <StepThree onNext={nextStep} onPrev={prevStep} step={step} />
        )}
        {step.step === 4 && (
          <StepFour onNext={nextStep} onPrev={prevStep} step={step} />
        )}
        {step.step === 5 && (
          <StepFive onNext={nextStep} onPrev={prevStep} step={step} />
        )}
        {step.step === 6 && (
          <StepSix onNext={nextStep} onPrev={prevStep} step={step} />
        )}
        {step.step === 7 && (
          <StepSeven startOver={startOver} step={step} />
        )}
      </section>
    </>
  );
}
