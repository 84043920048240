import { createSlice } from '@reduxjs/toolkit';
import * as actions from './actions';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false,
    loading: false,
    email: '',
    token: null,
    refreshToken: null,
    revalidatingToken: false,
  },
  reducers: {
    logoutUser(state) {
      state.isAuthenticated = false;
      state.loading = false;
      state.email = null;
      state.token = null;
      state.refreshToken = null;
    }
  },
  extraReducers: (builder) => {
    // User Login
    builder.addCase(actions.userLogin.pending, state => {
      state.loading = true;
    })
    builder.addCase(actions.userLogin.fulfilled, (state, action) => {
      if(!action.payload?.data.success) return;
      const { token, refreshToken, email } = action.payload?.data.data || {};
      state.isAuthenticated = true;
      state.loading = false;
      state.email = email;
      state.token = token;
      state.refreshToken = refreshToken;
    })
    builder.addCase(actions.userLogin.rejected, state => {
      state.loading = false;
    })

    //Refresh token
    builder.addCase(actions.getNewToken.pending, state => {
      state.loading = true;
      state.revalidatingToken = true;
    })
    builder.addCase(actions.getNewToken.fulfilled, (state, action) => {
      const { token } = action.payload?.data || {};
      if (action.payload?.success) {
        state.token = token;
        state.loading = false;
      } else {
        state.loading = false;
        state.isAuthenticated = false;
        state.token = null;
        state.refreshToken = null;
      }
      state.revalidatingToken = false;
    })
    builder.addCase(actions.getNewToken.rejected, state => {
      if (!state.revalidatingToken) {
        state.loading = false;
        state.isAuthenticated = false;
        state.email = null;
        state.token = null;
        state.refreshToken = null;
      }
    })

    //Check if authenticated
    builder.addCase(actions.checkAuth.pending, state => {
      state.loading = true;
    });
    builder.addCase(actions.checkAuth.fulfilled, (state, action) => {
      state.loading = false;
      const { success } = action.payload?.data || {};
      if (success === false && state.refreshToken !== null) {
        console.log('not authenticated, may need to refesh token');
      }
    })
      builder.addCase(actions.checkAuth.rejected, (state, action) => {
          state.loading = false;
          state.isAuthenticated = false;
          state.email = null;
          state.token = null;
          state.refreshToken = null;
      })

      // One time email Login
      builder.addCase(actions.getEmailAuth.pending, state => {
          state.loading = true;
      })
      builder.addCase(actions.getEmailAuth.fulfilled, (state, action) => {          
          const { token, refreshToken } = action.payload?.data || {};
          state.isAuthenticated = true;
          state.token = token;
          state.refreshToken = refreshToken;
          state.loading = false;
      })
      builder.addCase(actions.getEmailAuth.rejected, state => {
          state.loading = false;
      })

  }
});

export const { logoutUser } = authSlice.actions

export default authSlice.reducer;
